import { CAlert, CCol, CContainer, CRow } from '@coreui/react';
import 'firebaseui/dist/firebaseui.css';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { fs } from '../../firebase';
import AuthManager from '../../utils/AuthManager';
import Loading from '../components/Loading';

function AuthenticateFirebaseUser(props) {
  const { uid, accessToken } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const { setCurrentUser } = useAuth();
  const history = useHistory();

  const authenticate = async () => {
    try {
      const firebaseUser = await fs.collection('users').doc(uid).get();
      const data = {
        user: firebaseUser.data(),
        accessToken: accessToken,
      };
      const response = await AuthManager.fireBaseLogin(data);

      setCurrentUser(response.user);
      if (response.user.role === 'admin') {
        history.push('/admin');
      } else {
        history.push('/');
      }
    } catch (error) {
      setLoading(false);
      setError(error.message);
    }
  };

  useEffect(() => {
    authenticate();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className='c-app c-default-layout flex-row align-items-center'
      style={{ backgroundColor: '#fff', minHeight: '90vh' }}
    >
      <CContainer className={'flex-row align-items-center login-container'}>
        <CRow className='justify-content-center'>
          <CCol sm='12'>
            {loading && <Loading />}
            {error && <CAlert color={'danger'}>{error}</CAlert>}
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
}

export default withRouter(AuthenticateFirebaseUser);
