import {
  CCreateElement,
  CSidebar,
  CSidebarBrand,
  CSidebarMinimizer,
  CSidebarNav,
  CSidebarNavDivider,
  CSidebarNavDropdown,
  CSidebarNavItem,
  CSidebarNavTitle,
} from '@coreui/react';
import React, { useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import navigations from './_nav';

function Sidebar() {
  const { sidebarShow, setToggleSidebar } = useAuth();
  const [minimized, setMinimized] = useState(true);

  return (
    <CSidebar
      onMinimizeChange={(val) => {
        setMinimized(val);
      }}
      show={sidebarShow}
      onShowChange={(val) => {
        setToggleSidebar(val);
      }}
    >
      <CSidebarBrand className='d-md-down-none'>
        {minimized && (
          <img
            src={'../assets/logo.png'}
            alt='logo'
            style={{ height: '50px' }}
          />
        )}
        {!minimized && (
          <img
            src={'../assets/logo-circular.png'}
            alt='logo-circular'
            style={{ height: '40px' }}
          />
        )}
      </CSidebarBrand>
      <CSidebarNav>
        <CCreateElement
          items={navigations}
          components={{
            CSidebarNavDivider,
            CSidebarNavDropdown,
            CSidebarNavItem,
            CSidebarNavTitle,
          }}
        />
      </CSidebarNav>
      <CSidebarMinimizer className='c-d-md-down-none' />
    </CSidebar>
  );
}

export default React.memo(Sidebar);
