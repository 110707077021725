import {
  CAlert,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CContainer,
  CRow,
} from '@coreui/react';
import firebase from 'firebase/app';
import * as firebaseui from 'firebaseui';
import 'firebaseui/dist/firebaseui.css';
import React, { useEffect, useState } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { fs } from '../firebase';
import AuthManager from '../utils/AuthManager';
require('firebase/auth');
require('firebase/analytics');

function Login(props) {
  const [error, setError] = useState('');
  const { setCurrentUser } = useAuth();
  const history = useHistory();
  const ui =
    firebaseui.auth.AuthUI.getInstance() ||
    new firebaseui.auth.AuthUI(firebase.auth());

  const uiConfig = {
    callbacks: {
      signInSuccessWithAuthResult: async function (authResult, redirectUrl) {
        try {
          const accessToken = await authResult.user.getIdToken();
          const firebaseUser = await fs
            .collection('users')
            .doc(authResult.user.uid)
            .get();
          let user = {};

          if (firebaseUser.data() === undefined) {
            user = {
              name: authResult.user.displayName,
              email: authResult.user.email,
              id: authResult.user.uid,
              provider: authResult.user.providerData[0].providerId,
              providerAccountId: authResult.user.providerData[0].uid,
              role: 'user',
            };
          } else {
            user = firebaseUser.data();
          }

          const data = {
            user: user,
            accessToken: accessToken,
            idToken: authResult.credential.idToken,
          };

          const response = await AuthManager.fireBaseLogin(data);
          setCurrentUser(response.user);
          firebase.analytics().logEvent('login');
          history.push('/');
        } catch (error) {
          console.log(error);
          setError(error.message);
          return false;
        }
      },
      uiShown: function () {},
    },
    signInFlow: 'popup',
    // signInSuccessUrl: '/',
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      // {
      //   provider: 'apple.com',
      // },
    ],
    // tosUrl: '<your-tos-url>',
    // privacyPolicyUrl: '<your-privacy-policy-url>'
  };

  useEffect(() => {
    if (AuthManager.getCurrentUser()) {
      if (AuthManager.getCurrentUser().role === 'admin') {
        history.push('/admin');
      } else {
        history.push('/');
      }
    }

    ui.start('#firebaseui-auth-container', uiConfig);

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className='c-app c-default-layout flex-row align-items-center'
      style={{ backgroundColor: '#fff', minHeight: '80vh' }}
    >
      <CContainer className={'flex-row align-items-center login-container'}>
        <CRow className='justify-content-center'>
          <CCol className={'col-12'}>
            <CCard className='p-4' style={{ border: 'none' }}>
              <CCardHeader style={{ border: 'none' }}>
                {error && <CAlert color='danger'>{error}</CAlert>}
                {/* <div className={'text-center logo-container'}><img style={{ width: '100%' }} src={'../assets/logo.jpg'} /></div> */}
              </CCardHeader>
              <CCardBody>
                <div id={'firebaseui-auth-container'}></div>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
}

export default withRouter(Login);
