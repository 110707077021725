window.Api = {};
window.Api.Base = process.env.REACT_APP_BASE_API;
// process.env.REACT_APP_BASE_API
window.Api.User = {};
window.Api.User.Login = window.Api.Base + '/auth/login';
window.Api.User.FirebaseLogin = window.Api.Base + '/auth/firebase-login';
window.Api.User.Logout = window.Api.Base + '/auth/logout';
window.Api.User.RefreshToken = window.Api.Base + '/auth/refresh';
window.Api.User.Info = window.Api.Base + '/auth/info';

window.Api.Users = window.Api.Base + '/users';
window.Api.Settings = window.Api.Base + '/settings';
window.Api.Leagues = window.Api.Base + '/leagues';
window.Api.Rounds = window.Api.Base + '/rounds';
window.Api.GameWeeks = window.Api.Base + '/game-weeks';
window.Api.Matches = window.Api.Base + '/matches';
window.Api.Pages = window.Api.Base + '/pages';
window.Api.Razorpay = window.Api.Base + '/razorpay';
window.Api.Transactions = window.Api.Base + '/transactions';
window.Api.Predictions = window.Api.Base + '/predictions';
window.Api.Rewards = window.Api.Base + '/rewards';
window.Api.UserRewards = window.Api.Base + '/users/rewards';
window.Api.RoundResult = window.Api.Base + '/round-result';
window.Api.UploadFile = window.Api.Base + '/upload';
