import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AuthProvider } from './contexts/AuthContext';
import AdminLayout from './layouts/backend/Layout';
import FrontendLayout from './layouts/frontend/Layout';
import AuthenticateFirebaseUser from './pages/frontend/AuthenticateFirebaseUser';

toast.configure()

function App() {

  const loadScripts = async (scripts) => {
    for (var i = 0; i < scripts.length; i++) {
      const script = document.createElement('script')
      script.src = scripts[i]
      document.body.appendChild(script)
    }
  }

  useEffect(() => {

    loadScripts([
      'https://checkout.razorpay.com/v1/checkout.js',
    ]);

    return () => {

    }
  }, [])

  return (
    <AuthProvider>
      <Router>
        <Switch>
          <Route path='/auth/:uid/:accessToken' component={AuthenticateFirebaseUser} />
          <Route path='/admin' render={() => <AdminLayout />} />
          <Route path='/' render={() => <FrontendLayout />} />
        </Switch>
      </Router>
    </AuthProvider>
  );
}

export default App;
