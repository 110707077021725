import {
  CAlert,
  CButton, CCol, CContainer, CModal,
  CModalBody, CModalFooter, CRow
} from '@coreui/react'
import React from 'react'

export default function AccountErrorModal(props) {
  return (
    <CModal
      show={true}
      closeOnBackdrop={false}
      centered={true}
    >
      <CModalBody>
        <CContainer fluid>
          <CRow>
            <CCol>
              <CAlert color='info'><h5>Update your bank account</h5></CAlert>
            </CCol>
          </CRow>
        </CContainer>
      </CModalBody>
      <CModalFooter>
        <CButton color={'success'} className="btn-sm pl-4 pr-4"
          to={'/rewards'}
        >
          Update Bank Account
        </CButton>
      </CModalFooter>
    </CModal>
  )
}
