import { CAlert, CCol, CPagination, CRow, CSelect } from '@coreui/react';
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import AuthManager from '../../utils/AuthManager';
import Backend from '../../utils/Backend';
import General from '../../utils/General';

function Results() {
  const [leagueId, setLeagueId] = useState(null);
  const [roundId, setRoundId] = useState(null);
  const [leagues, setLeagues] = useState([]);
  const [rounds, setRounds] = useState([]);
  const [currentUserStat, setCurrentUserStat] = useState(null);
  const [results, setResults] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const perPage = 20;

  const handleLeagueChange = (e) => {
    setRounds([]);
    const league = leagues.find(
      (league) => league.id === Number(e.target.value),
    );
    setLeagueId(league.id);
    setRounds([...league.rounds]);
    if (league.rounds.length) {
      setRoundId(league.rounds[0].id);
    }
  };

  const handleRoundChange = (e) => {
    setRoundId(Number(e.target.value));
  };

  const getLeagues = async () => {
    const param = '?has_active_round=0';
    try {
      const response = await Backend.getAllLeagues(param);

      if (response) {
        setLeagues([...response]);
        if (response.length) {
          setRounds([...response[0].rounds]);
          setLeagueId(response[0].id);
          if (response[0].rounds.length) {
            setRoundId(response[0].rounds[0].id);
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getResults = async () => {
    setResults([]);
    try {
      let params = `?per_page=${perPage}&page=${currentPage}&round_id=${roundId}`;

      let round = rounds.find((round) => round.id === roundId);
      if (round === undefined) {
        return;
      }

      if (round.result === 'announced') {
        const response = await Backend.getRoundResults(
          leagueId,
          roundId,
          params,
          AuthManager.getCurrentUser() ? true : false,
        );
        if (response.data) {
          setLastPage(response.data.last_page);
          setResults([...response.data.data]);
          setCurrentUserStat(response.current_user);
        }
      } else {
        setLastPage(1);
        setResults([]);
        setCurrentUserStat(null);
        return;
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getLeagues();
  }, []);

  useEffect(() => {
    getResults();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roundId, currentPage]);

  return (
    <>
      <CRow className={'mt-2 mb-2'}>
        <CCol>
          <h5>RESULTS</h5>
        </CCol>
      </CRow>

      <CRow className={'pt-3 pb-3 league-select-container'}>
        <CCol className={'col-8 pr-0'}>
          <CSelect
            onChange={(e) => {
              handleLeagueChange(e);
            }}
          >
            {leagues.map((league) => {
              return (
                <option key={league.id} value={league.id}>
                  {league.name}
                </option>
              );
            })}
          </CSelect>
        </CCol>
        <CCol className={'col-4'}>
          <CSelect
            onChange={(e) => {
              handleRoundChange(e);
            }}
          >
            {rounds.map((round) => {
              return (
                <option key={round.id} value={round.id}>
                  {round.name}
                </option>
              );
            })}
          </CSelect>
        </CCol>
      </CRow>

      {currentUserStat && (
        <CRow className={'mt-3'}>
          <CCol>
            <CAlert color={'success'} className={'w-100'}>
              <CRow>
                <CCol className={'col-1 text-left'}>
                  <strong>{currentUserStat.position}</strong>
                </CCol>
                <CCol className={'col-7 text-left'}>
                  <strong>{currentUserStat.name}</strong>
                  <br />
                </CCol>
                <CCol className={'col-3 text-right'}>
                  <strong>{currentUserStat.prediction_points} points</strong>
                </CCol>
              </CRow>
            </CAlert>
          </CCol>
        </CRow>
      )}

      <CRow>
        <CCol>
          {results.length === 0 ? (
            <h3 className='text-center mt-4'>Result is not announced yet</h3>
          ) : (
            results.map((result, index) => {
              return (
                <div key={index} className={'mt-3 mb-2 w-100'}>
                  <CRow>
                    <CCol className={'col-1 text-left'}>
                      <strong>{(currentPage - 1) * perPage + ++index}</strong>
                    </CCol>
                    <CCol className={'col-6 text-left'}>
                      <strong>{result.name}</strong>
                      <br />
                      <strong>
                        Prize:{' '}
                        {result.reward_name && result.reward_prize
                          ? `${General.formatRupees(
                              result.reward_prize,
                            )} (${result.reward_name.toUpperCase()})`
                          : '-'}
                        {/* <small className={'color-grey'}>Account ID: {result.provider_id}</small> */}
                      </strong>
                    </CCol>
                    <CCol className={'col-4 text-right'}>
                      <strong>{result.prediction_points} points</strong>
                      <br />
                      <strong>
                        <small className={'color-grey'}>
                          P-{result.prediction_points} W-
                          {result.num_winner_predictions} G-
                          {result.num_predictions} C-{result.num_comments}{' '}
                        </small>
                      </strong>
                      <strong>
                        <small className={'color-grey'}>
                          Avg Time taken:{' '}
                          {parseFloat(result.prediction_time / 60).toFixed(2)}
                          hrs
                        </small>
                      </strong>
                    </CCol>
                  </CRow>
                </div>
              );
            })
          )}
        </CCol>
      </CRow>

      {results.length > 0 && (
        <CRow className={'text-center mt-3'}>
          <CCol>
            <CPagination
              className={'mt-2'}
              activePage={currentPage}
              pages={lastPage}
              onActivePageChange={(i) => setCurrentPage(i)}
            ></CPagination>
          </CCol>
        </CRow>
      )}
    </>
  );
}

export default withRouter(Results);
