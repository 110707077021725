import { CIcon } from '@coreui/icons-react';
import {
  CAlert,
  CBadge,
  CButton,
  CCol,
  CDataTable,
  CFormGroup,
  CRow,
  CSelect,
} from '@coreui/react';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import FileSaver from 'file-saver';
import { withRouter } from 'react-router-dom';
import { defaultConfirmOptions } from '../../../../constants/Options';
import Backend from '../../../../utils/Backend';
import Notify from '../../../../utils/Notify';
import ConfirmationModal from '../../modals/ConfirmationModal';
import { fs } from '../../../../firebase';

function Results(props) {
  const [round, setRound] = useState(null);
  const [results, setResults] = useState([]);
  const [rewards, setRewards] = useState([]);
  const [userRewards, setUserRewards] = useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [confirmOptions, setConfirmOptions] = useState(defaultConfirmOptions);

  const generateRoundResult = async (round) => {
    try {
      let allComments = [];

      const commentRef = await fs
        .collectionGroup('comments')
        .where('createdAt', '>', moment(round.start).toDate())
        .get();

      commentRef.docs.forEach((comment) => {
        let createdDate = moment(comment.data().createdAt.toDate());
        if (
          createdDate.isSameOrAfter(moment(round.start)) &&
          createdDate.isSameOrBefore(moment(round.end))
        ) {
          allComments.push(comment.data().author_id);
        }
      });

      await Backend.generateRoundResult({
        round_id: round.id,
        league_id: round.league_id,
        comments: allComments,
      });

      await getResult(round);
    } catch (error) {
      console.log(error);
    }
  };

  const handleRewardChange = (item, e) => {
    let updatedUserRewards = userRewards;
    let exist = userRewards.find((reward) => reward.user_id === item.user_id);
    if (exist === undefined) {
      if (e.target.value) {
        let rewardArr = e.target.value.split('-');
        updatedUserRewards.push({
          id: item.id,
          user_id: item.user_id,
          reward_id: rewardArr[0],
          reward_rank: rewardArr[1],
        });
      }
    } else {
      // Remove if no reward is selected
      if (!e.target.value) {
        updatedUserRewards = updatedUserRewards.filter(
          (reward) => reward.user_id !== item.user_id,
        );
      } else {
        updatedUserRewards = updatedUserRewards.map((reward) => {
          let rewardArr = e.target.value.split('-');
          return reward.user_id !== item.user_id
            ? reward
            : {
                ...reward,
                reward_id: rewardArr[0],
                reward_rank: rewardArr[1],
              };
        });
      }
    }

    setUserRewards([...updatedUserRewards]);
  };

  const announceRoundResult = async (round) => {
    try {
      await Backend.announceRoundResult(round.id, { results: userRewards });
      setShowConfirmModal(false);
      setRound({ ...round, result: 'announced' });
      await getResult(round);
      Notify.success(`${round.name} result announced successfully`);
    } catch (error) {
      setShowConfirmModal(false);
      Notify.error(error.message);
    }
  };

  const getResult = async (round) => {
    const response = await Backend.getRoundResults(round.league_id, round.id);
    setResults([...response.data]);
  };

  const getRewards = async (round) => {
    const response = await Backend.getRewards();
    setRewards([...response.data]);
  };

  useEffect(() => {
    getRewards();
  }, []);

  useEffect(() => {
    setRound(props.round);

    if (props.round.result !== 'pending') {
      getResult(props.round);
    }
  }, [props.round]);

  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const exportToExcel = (apiData) => {
    const processedData = apiData.map((data) => {
      data['rank'] = data.reward_rank;
      delete data.reward_rank;
      delete data.created_at;
      delete data.deleted_at;
      delete data.updated_at;
      delete data.num_comments;
      delete data.reward_id;
      delete data.prediction_time;
      delete data.id;
      delete data.user_id;
      delete data.league_id;
      delete data.round_id;
      delete data.total_points;

      return data;
    });
    const ws = XLSX.utils.json_to_sheet(processedData);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(
      data,
      `${round.name}_${new Date(round.start).getFullYear()}_results` +
        fileExtension,
    );
  };

  return (
    <>
      <CRow className={'mt-2'}>
        {round && round.result === 'announced' && (
          <CCol className='text-right'>
            <CButton
              color='dark'
              className='btn-sm'
              onClick={() => exportToExcel(results)}
            >
              <CIcon name='cil-file' className='mr-2' />
              Export result
            </CButton>
          </CCol>
        )}
        <CCol className={'col-sm-12 text-right'}>
          {round && round.result !== 'announced' && (
            <>
              <CButton
                color='dark'
                className='btn-sm'
                onClick={(e) => {
                  generateRoundResult(props.round);
                }}
              >
                Generate Round Results
              </CButton>
              <CButton
                color='success'
                className='btn-sm ml-2'
                onClick={(e) => {
                  setConfirmOptions({
                    ...confirmOptions,
                    title: 'Confirm Round Results',
                    type: 'danger',
                    content: `Are you sure you want to announce this Round Result? This action is irreversible.`,
                    confirmText: `CONFIRM RESULTS`,
                    cancelButton: true,
                    onConfirm: () => {
                      announceRoundResult(props.round);
                    },
                  });
                  setShowConfirmModal(true);
                }}
              >
                Announce Round Result
              </CButton>
            </>
          )}
        </CCol>
        <CCol className={'col-12 mt-3'}>
          <CDataTable
            items={results}
            striped
            outlined={true}
            fields={[
              {
                key: 'actions',
                label:
                  round && round.result === 'announced'
                    ? 'Reward'
                    : 'Select Reward',
              },
              {
                key: 'position',
                label: '#',
              },
              {
                key: 'name',
                label: 'Name',
              },
              {
                key: 'email',
                label: 'Email',
              },
              {
                key: 'prediction_points',
                label: 'Prediction Points',
              },
              {
                key: 'num_winner_predictions',
                label: 'Winner Predictions',
              },
              {
                key: 'num_predictions',
                label: 'Predictions',
              },
              {
                key: 'num_comments',
                label: 'Comments',
              },
            ]}
            scopedSlots={{
              position: (item, index) => {
                return <td>{index + 1}</td>;
              },
              actions: (item) => {
                return (
                  <td>
                    {round.result === 'announced' ? (
                      <>
                        <CBadge className={'reward'}>
                          {item.reward_name && item.reward_name.toUpperCase()}
                        </CBadge>
                      </>
                    ) : (
                      <>
                        <CFormGroup>
                          <CSelect
                            className={'reward-select'}
                            onChange={(e) => {
                              handleRewardChange(item, e);
                            }}
                          >
                            <option value=''>Select</option>
                            {rewards.map((reward) => {
                              return (
                                <option value={`${reward.id}-${reward.rank}`}>
                                  {reward.name.toUpperCase()}
                                </option>
                              );
                            })}
                          </CSelect>
                        </CFormGroup>
                      </>
                    )}
                  </td>
                );
              },
            }}
          />
        </CCol>
      </CRow>

      {showConfirmModal && (
        <ConfirmationModal
          show={showConfirmModal}
          title={confirmOptions.title}
          content={() => (
            <>
              <CAlert color={confirmOptions.type} className='w-100'>
                {confirmOptions.content}
              </CAlert>
            </>
          )}
          onClose={() => {
            setShowConfirmModal(false);
          }}
          confirmText={confirmOptions.confirmText}
          onConfirm={confirmOptions.onConfirm}
        />
      )}
    </>
  );
}

export default withRouter(Results);
