import {
  CAlert,
  CButton,
  CCol,
  CContainer,
  CForm,
  CFormGroup,
  CInput,
  CLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CSpinner,
  CSelect,
  CSwitch,
} from '@coreui/react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Backend from '../../../utils/Backend';
import Notify from '../../../utils/Notify';
import CustomDatePicker from '../../components/CustomDatePicker';

export default function RoundModal(props) {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [leagues, setLeagues] = useState([]);
  const [round, setRound] = useState({
    id: '',
    league_id: '',
    name: '',
    prize_pool: 0,
    max_rewardees: 0,
    start: '',
    end: '',
    active: true,
  });

  function handleFormChange(evt) {
    setError('');
    let name = evt.target.name;
    let value = evt.target.value;
    setRound({ ...round, [name]: value });
  }

  function validateData() {
    if (!round.league_id) {
      setError('Select League');
      return false;
    } else if (!round.name) {
      setError('Enter Round name');
      return false;
    } else if (isNaN(round.prize_pool)) {
      setError('Enter valid value of Prize Pool');
      return false;
    } else if (!round.start) {
      setError('Select Round start date');
      return false;
    } else if (!round.end) {
      setError('Select Round end date');
      return false;
    } else if (isNaN(round.max_rewardees)) {
      setError('Enter valide value for Maximum Rewardees');
    }

    return true;
  }

  async function handleSubmit() {
    setLoading(true);

    if (!validateData()) {
      setLoading(false);
      return;
    }

    try {
      if (!props.round) {
        const response = await Backend.addRound(round);
        props.onAdded(response.data);
        Notify.success('Round added successfully.');
      } else {
        const response = await Backend.updateRound(round.id, round);
        props.onUpdated(response.data);
        Notify.success('Round updated successfully.');
      }
      props.onClose();
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  }

  const getLeagues = async () => {
    try {
      const response = await Backend.getAllLeagues();
      if (response) {
        setLeagues([...response]);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      Notify.error(error.message);
    }
  };

  useEffect(() => {
    getLeagues();

    if (props.round) {
      console.log(props.round);
      setRound(props.round);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <CModal show={props.show} centered={true} onClose={() => props.onClose()}>
        <CModalHeader closeButton>
          <CModalTitle>{props.round ? 'Add' : 'Edit'} Round</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CContainer fluid>
            <CRow>
              {error && (
                <CAlert color='danger' className='w-100'>
                  {error}
                </CAlert>
              )}
              <CCol sm='12'>
                <CForm
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                  }}
                >
                  <CFormGroup>
                    <CLabel>Select League</CLabel>
                    <CSelect
                      value={round.league_id}
                      name='league_id'
                      onChange={(e) => {
                        handleFormChange(e);
                      }}
                    >
                      <option value=''>Select League</option>
                      {leagues.map((league) => {
                        return <option value={league.id}>{league.name}</option>;
                      })}
                    </CSelect>
                  </CFormGroup>
                  <CFormGroup>
                    <CLabel>Round Name</CLabel>
                    <CInput
                      value={round.name}
                      type='text'
                      name='name'
                      placeholder='Enter Round Name'
                      onChange={(e) => handleFormChange(e)}
                    />
                  </CFormGroup>
                  <CFormGroup>
                    <CLabel>Prize Pool (in Rupees)</CLabel>
                    <CInput
                      value={round.prize_pool}
                      type='number'
                      min={0}
                      name='prize_pool'
                      placeholder='Enter Round Name'
                      onChange={(e) => handleFormChange(e)}
                    />
                  </CFormGroup>
                  <CFormGroup>
                    <CLabel>Maximum number of rewardees</CLabel>
                    <CInput
                      value={round.max_rewardees}
                      type='number'
                      min={0}
                      name='max_rewardees'
                      placeholder='Enter number of rewardees'
                      onChange={(e) => handleFormChange(e)}
                    />
                  </CFormGroup>
                  <CFormGroup>
                    <CustomDatePicker
                      label={'Select start date'}
                      value={
                        props.round
                          ? moment(props.round.start).format('DD/MM/YYYY')
                          : ''
                      }
                      onApply={(date) => {
                        setError('');
                        setRound({
                          ...round,
                          start: moment(date).format('YYYY-MM-DD'),
                        });
                      }}
                    />
                  </CFormGroup>
                  <CFormGroup>
                    <CustomDatePicker
                      label={'Select end date'}
                      value={
                        props.round
                          ? moment(props.round.end).format('DD/MM/YYYY')
                          : ''
                      }
                      onApply={(date) => {
                        setError('');
                        setRound({
                          ...round,
                          end: moment(date).format('YYYY-MM-DD'),
                        });
                      }}
                    />
                  </CFormGroup>
                  <CFormGroup>
                    <CLabel>Active: </CLabel>
                    <CSwitch
                      className='text-right ml-2'
                      checked={round.active}
                      color='dark'
                      size='lg'
                      variant='3d'
                      shape='pill'
                      onChange={(e) => {
                        setError('');
                        setRound({ ...round, active: !round.active });
                      }}
                    />
                  </CFormGroup>
                </CForm>
              </CCol>
            </CRow>
          </CContainer>
        </CModalBody>
        <CModalFooter>
          <CButton
            variant='outline'
            color='danger'
            className='btn-sm pl-4 pr-4'
            disabled={loading}
            onClick={() => props.onClose()}
          >
            Cancel
          </CButton>
          <CButton
            color='dark'
            className='btn-sm pl-4 pr-4'
            disabled={loading}
            onClick={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            {loading ? (
              <>
                <CSpinner component='span' size='sm' aria-hidden='true' />{' '}
                Saving...
              </>
            ) : props.round ? (
              'Update Round'
            ) : (
              'Add Round'
            )}
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  );
}
