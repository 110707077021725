import { CIcon } from '@coreui/icons-react';
import {
  CAlert,
  CButton,
  CCol,
  CContainer,
  CFormGroup,
  CInput,
  CLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CSpinner,
  CTooltip,
} from '@coreui/react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Backend from '../../../utils/Backend';
import Notify from '../../../utils/Notify';
// import CustomDatePicker from '../../components/CustomDatePicker';

export default function GameWeekModal(props) {
  const gameWeekModel = {
    id: '',
    round_id: props.round.id,
    name: '',
  };
  const [error, setError] = useState('');
  const [loading] = useState(false);
  const [round, setRound] = useState(null);
  const [matches, setMatches] = useState([]);
  const [gameWeekMatches, setGameWeekMatches] = useState([]);
  const [gameWeek, setGameWeek] = useState(gameWeekModel);

  const addRemoveMatch = (matchId) => {
    setError('');
    let data = gameWeekMatches;
    if (data.includes(matchId)) {
      data = data.filter((id) => matchId !== id && id);
    } else {
      data.push(matchId);
    }

    setGameWeekMatches([...data]);
  };

  const initMatches = async () => {
    try {
      const response = await Backend.getLeagueMatches(round.league_id);
      if (response.data) {
        setMatches([...response.data]);
        console.log('axsxasxsa');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const addGameWeek = async () => {
    setError('');

    if (!gameWeek.name) {
      setError('Enter Game week name');
      return;
    }

    try {
      if (gameWeek.id) {
        let removeMatchIds = gameWeek.matches.filter(
          (match) => !gameWeekMatches.includes(match.id) && match,
        );

        const response = await Backend.updateGameWeek(gameWeek.id, gameWeek);
        if (response.data) {
          if (gameWeekMatches.length > 0) {
            await addMatchesToGameWeek(gameWeek.id);
          }

          if (removeMatchIds.length > 0) {
            await removeMatchesFromGameWeek(removeMatchIds);
          }
          props.onClose();
          Notify.success('Game week updated successfully.');
        }
      } else {
        const response = await Backend.addGameWeek(gameWeek);
        if (response.data) {
          if (gameWeekMatches.length > 0) {
            await addMatchesToGameWeek(response.data.id);
          }
          props.onClose();
          Notify.success('Game week added successfully.');
        }
      }
    } catch (error) {
      console.log(error);
      Notify.error(error.message);
    }
  };

  const addMatchesToGameWeek = async (gameWeekId) => {
    await Promise.all(
      gameWeekMatches.map(async (matchId) => {
        try {
          await Backend.updateMatch(matchId, { game_week_id: gameWeekId });
        } catch (error) {
          // setError(error.message)
          Notify.error(error.message);
        }
      }),
    );
  };

  const removeMatchesFromGameWeek = async (matches) => {
    await Promise.all(
      matches.map(async (match) => {
        try {
          console.log(match, match.id);
          await Backend.updateMatch(match.id, { game_week_id: null });
        } catch (error) {
          // setError(error.message)
          Notify.error(error.message);
        }
      }),
    );
  };

  const initRound = async () => {
    try {
      const response = await Backend.getRound(props.round.id);
      if (response.data) {
        setRound(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    initRound();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    initMatches();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [round]);

  return (
    <>
      <CModal
        show={props.show}
        centered={true}
        size={'xl'}
        className={'fw-modal'}
        onClose={() => props.onClose()}
      >
        <CModalHeader closeButton>
          <CModalTitle>Add/Edit Game Week </CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CContainer fluid>
            <CRow>
              {round && round.game_weeks.length > 0 && (
                <h5>Existing Game Weeks</h5>
              )}
              {round &&
                round.game_weeks.map((gameWeek) => {
                  return (
                    <CAlert
                      color={'dark'}
                      className={'w-100 mb-1 cursor-pointer'}
                    >
                      <strong>{gameWeek.name}</strong>
                      <CButton
                        color={'dark'}
                        className={'btn-sm'}
                        style={{ float: 'right' }}
                        onClick={() => {
                          setGameWeek(gameWeek);
                          setGameWeekMatches([
                            ...gameWeek.matches.map((match) => match.id),
                          ]);
                        }}
                      >
                        <CIcon name={'cil-pen-alt'} title={'edit'} />
                      </CButton>
                    </CAlert>
                  );
                })}
            </CRow>
            <CRow className={'mt-3'}>
              {error && (
                <CAlert color={'danger'} className={'w-100'}>
                  {error}
                </CAlert>
              )}
            </CRow>
            <CFormGroup>
              <CLabel>Game Week Name</CLabel>
              <CInput
                value={gameWeek.name}
                type='text'
                name='name'
                placeholder='Enter Game Week Name'
                onChange={(e) => {
                  setGameWeek({ ...gameWeek, name: e.target.value });
                }}
              />
            </CFormGroup>
            <CRow className={'add-matches-container'}>
              {matches.map((item, index) => {
                if (item.result_announce_at !== null) {
                  return undefined;
                }

                return (
                  <CCol className={'mb-0 col-sm-6'}>
                    <CAlert
                      color={item.result_announce_at !== null ? 'dark' : 'info'}
                      className={''}
                    >
                      <CRow>
                        <CCol className={'col-sm-3'}>
                          <CFormGroup>
                            <CLabel>Home Team</CLabel>
                            <br />
                            <CLabel>
                              <strong>{item.team1}</strong>
                            </CLabel>
                            <br />
                            {item.score1 != null && (
                              <CLabel className={'text-center'}>
                                <strong>{item.score1} </strong>
                              </CLabel>
                            )}
                          </CFormGroup>
                        </CCol>
                        <CCol className={'col-sm-3'}>
                          <CFormGroup>
                            <CLabel>Away Team</CLabel>
                            <br />
                            <CLabel>
                              <strong>{item.team2}</strong>
                            </CLabel>
                            <br />
                            {item.score2 != null && (
                              <CLabel className={'text-center'}>
                                <strong>{item.score2}</strong>
                              </CLabel>
                            )}
                          </CFormGroup>
                        </CCol>
                        <CCol className={'col-sm-4'}>
                          <CFormGroup>
                            <CLabel>Kickoff</CLabel>
                            <br />
                            <CLabel>
                              <strong>
                                {moment(item.start_at).format(
                                  'DD/MM/YYYY hh:mm A',
                                )}
                              </strong>
                            </CLabel>
                          </CFormGroup>
                        </CCol>
                        <CCol className={'col-sm-2'}>
                          <CFormGroup>
                            {gameWeekMatches.includes(item.id) ? (
                              <CButton
                                color='danger'
                                className='btn-sm'
                                onClick={(e) => {
                                  addRemoveMatch(item.id);
                                }}
                              >
                                <CIcon name={'cil-trash'} />
                              </CButton>
                            ) : (
                              <CButton
                                color='success'
                                className='btn-sm'
                                onClick={(e) => {
                                  addRemoveMatch(item.id);
                                }}
                              >
                                <CIcon name={'cil-plus'} />
                              </CButton>
                            )}
                            {item.game_week_id != null &&
                              !gameWeekMatches.includes(item.id) &&
                              item.result_announce_at == null && (
                                <>
                                  <CTooltip
                                    className={'mt-1'}
                                    content={`This Match is already added in another Game Week. 
                                    Adding to this Game Week will result in removal from previous Game Week`}
                                  >
                                    <CButton
                                      color='warning'
                                      className='btn-sm mt-2'
                                    >
                                      <CIcon name={'cil-warning'} />
                                    </CButton>
                                  </CTooltip>
                                </>
                              )}
                          </CFormGroup>
                        </CCol>
                      </CRow>
                    </CAlert>
                  </CCol>
                );
              })}
            </CRow>
          </CContainer>
        </CModalBody>
        <CModalFooter>
          <CButton
            color='info'
            className='btn-sm pl-4 pr-4'
            disabled={loading}
            onClick={(e) => {
              e.preventDefault();
              addGameWeek();
            }}
          >
            {loading ? (
              <>
                <CSpinner component='span' size='sm' aria-hidden='true' />{' '}
                Saving...
              </>
            ) : (
              'Add/Update Game Week'
            )}
          </CButton>
          <CButton
            color='dark'
            className='btn-sm pl-4 pr-4'
            disabled={loading}
            onClick={(e) => {
              e.preventDefault();
              setGameWeek(gameWeekModel);
              setGameWeekMatches([]);
            }}
          >
            {loading ? (
              <>
                <CSpinner component='span' size='sm' aria-hidden='true' />{' '}
                Saving...
              </>
            ) : (
              'Reset'
            )}
          </CButton>
          <CButton
            variant='outline'
            color='danger'
            className='btn-sm pl-4 pr-4 ml-2'
            disabled={loading}
            onClick={() => props.onClose()}
          >
            Close
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  );
}
