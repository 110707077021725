// import { CIcon } from '@coreui/icons-react'
import { CCol, CRow } from '@coreui/react';
import React from 'react';

export default function Dashboard() {
  return (
    <>
      <CRow>
        <CCol>
          <h4>Dashboard</h4>
        </CCol>
      </CRow>

      <CRow className={'mt-3'}>
        <CCol>
          {/* <CCardGroup className="mb-4">

            <CWidgetProgressIcon
              header={``}
              text=""
              color="gradient-success"
              inverse
            >
              <CIcon name="cil-people" height="36" />
            </CWidgetProgressIcon>

          </CCardGroup> */}
        </CCol>
      </CRow>
    </>
  );
}
