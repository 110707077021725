import { CCol, CDataTable, CRow } from '@coreui/react';
import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
// import { defaultConfirmOptions } from '../../../../constants/Options';
import Backend from '../../../../utils/Backend';
import General from '../../../../utils/General';

function Details(props) {
  const [data, setData] = useState(null);
  // const [showConfirmModal, setShowConfirmModal] = useState(false);
  // const [confirmOptions, setConfirmOptions] = useState(defaultConfirmOptions);

  const initDetails = async () => {
    try {
      const response = await Backend.getRoundDetails(props.round.id);
      setData(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    initDetails();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.round]);

  return (
    <>
      <CRow className={'mt-2'}>
        <CCol className={'col-sm-12'}>
          <strong>ROUND DETAILS</strong>
          <hr className={'mt-1'} />
        </CCol>
        {data && (
          <>
            <CCol className={'col-sm-12'}>
              <dl className='row'>
                <dt className='col-sm-4'>Number of participants :</dt>
                <dd className='col-sm-8'> {data.participants.length} </dd>
                <dt className='col-sm-4'>Prize Pool :</dt>
                <dd className='col-sm-8'>
                  {General.formatRupees(data.round.prize_pool)}
                </dd>
                {data.rewards.map((reward) => {
                  return (
                    <>
                      <dt className='col-sm-4'>
                        {reward.name} ({reward.percentage}%):
                      </dt>
                      <dd className='col-sm-8'>
                        {General.formatRupees(
                          Math.floor(
                            (reward.percentage / 100) * data.round.prize_pool,
                          ),
                        )}
                      </dd>
                    </>
                  );
                })}
              </dl>
            </CCol>
            <CCol className={'col-12 mt-3'}>
              <CDataTable
                items={data.participants}
                striped
                outlined={true}
                fields={[
                  {
                    key: 'slno',
                    label: 'Sl No.',
                  },
                  {
                    key: 'name',
                    label: 'Name',
                  },
                  {
                    key: 'email',
                    label: 'Email',
                  },
                ]}
                scopedSlots={{
                  slno: (item, index) => {
                    return <td>{index + 1}</td>;
                  },
                  // 'name': (item) => {
                  //   return (
                  //     <td>{item.name}</td>
                  //   )
                  // },
                  // 'name': (item) => {
                  //   return (
                  //     <td>{item.user.email}</td>
                  //   )
                  // },
                }}
              />
            </CCol>
          </>
        )}
      </CRow>
    </>
  );
}

export default withRouter(Details);
