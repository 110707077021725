import {
  CAlert,
  CButton,
  CCol,
  CFormGroup,
  CInput,
  CLabel,
  CRow,
} from '@coreui/react';
import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import AuthManager from '../../utils/AuthManager';
import Backend from '../../utils/Backend';
import Notify from '../../utils/Notify';
import Loading from '../components/Loading';

function Rewards(props) {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [rewards, setRewards] = useState([]);
  const [data, setData] = useState({
    phone_no: AuthManager.getCurrentUser().phone_no,
    account_no: AuthManager.getCurrentUser().account_no,
    account_holder: AuthManager.getCurrentUser().account_holder,
    bank_name: AuthManager.getCurrentUser().bank_name,
    ifsc: AuthManager.getCurrentUser().ifsc,
    branch: AuthManager.getCurrentUser().branch,
  });

  function handleFormChange(evt) {
    setError('');
    let name = evt.target.name;
    let value = evt.target.value;
    setData({ ...data, [name]: value });
  }

  const handleSubmit = async () => {
    let valid = true;
    if (!data.phone_no) {
      valid = false;
      setError('Enter phone number');
    } else if (!data.account_no) {
      valid = false;
      setError('Enter account number');
    } else if (!data.account_holder) {
      valid = false;
      setError('Enter account holder name');
    } else if (!data.bank_name) {
      valid = false;
      setError('Enter bank_name');
    } else if (!data.ifsc) {
      valid = false;
      setError('Enter IFSC');
    } else if (!data.branch) {
      valid = false;
      setError('Enter branch');
    }

    if (!valid) {
      return;
    }

    try {
      await Backend.updateUser(AuthManager.getCurrentUser().id, data);
      Notify.success('Your account details updated successfully');
      setTimeout(() => {
        window.location = '/';
      }, 1500);
    } catch (error) {
      Notify.error(error.message);
    }
  };

  const updateReward = async (id) => {
    try {
      await Backend.updateUserReward(id, { claimed: true });
      setRewards([
        ...rewards.map((reward) => {
          if (reward.id === id) {
            return { ...reward, claimed: true };
          }

          return reward;
        }),
      ]);
      Notify.success('Reward claimed successfully.');
    } catch (error) {
      Notify.error(error.message);
    }
  };

  const getRewards = async () => {
    try {
      const response = await Backend.getUserRewards();
      setRewards([...response.data]);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getRewards();
  }, []);

  return (
    <>
      <CRow className={'mt-2 mb-2'}>
        <CCol>
          <h5>MY REWARDS</h5>
        </CCol>
      </CRow>

      {AuthManager.getCurrentUser() && (
        <CRow className={'mt-2 mb-2'}>
          <CCol className={'col-12'}>
            <CAlert color={'info'} className={'w-100'}>
              Your phone number will be used for communications and your Rewards
              will be transfer to Bank details below.{' '}
            </CAlert>
          </CCol>
          {error && (
            <CCol className={'col-12'}>
              <CAlert color={'danger'} className={'w-100'}>
                {error}
              </CAlert>
            </CCol>
          )}

          <CCol className={'col-12 mt-2'}>
            <CFormGroup>
              <CLabel>Phone Number</CLabel>
              <CInput
                value={data.phone_no}
                type='text'
                name='phone_no'
                placeholder='Enter your phone Number'
                onChange={(e) => handleFormChange(e)}
              />
            </CFormGroup>
            <CFormGroup>
              <CLabel>
                <strong>Bank Details: </strong>
              </CLabel>
            </CFormGroup>
            <CFormGroup>
              <CLabel>Account No</CLabel>
              <CInput
                value={data.account_no}
                type='text'
                name='account_no'
                placeholder='Enter your account number'
                onChange={(e) => handleFormChange(e)}
              />
            </CFormGroup>
            <CFormGroup>
              <CLabel>Account Holder Name</CLabel>
              <CInput
                value={data.account_holder}
                type='text'
                name='account_holder'
                placeholder='Enter Account Holder Name'
                onChange={(e) => handleFormChange(e)}
              />
            </CFormGroup>
            <CFormGroup>
              <CLabel>Bank name</CLabel>
              <CInput
                value={data.bank_name}
                type='text'
                name='bank_name'
                placeholder='Enter bank name'
                onChange={(e) => handleFormChange(e)}
              />
            </CFormGroup>
            <CFormGroup>
              <CLabel>IFSC</CLabel>
              <CInput
                value={data.ifsc}
                type='text'
                name='ifsc'
                placeholder='Enter IFSC'
                onChange={(e) => handleFormChange(e)}
              />
            </CFormGroup>
            <CFormGroup>
              <CLabel>Branch Name</CLabel>
              <CInput
                value={data.branch}
                type='text'
                name='branch'
                placeholder='Enter branch name'
                onChange={(e) => handleFormChange(e)}
              />
            </CFormGroup>
            <CButton
              color='dark'
              className='btn-sm pl-4 pr-4'
              onClick={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              Save / Update
            </CButton>
          </CCol>
        </CRow>
      )}

      <CRow className={'mt-5 mb-2'}>
        <CCol>
          {loading ? (
            <Loading />
          ) : (
            rewards.map((reward, i) => {
              return (
                <CRow key={i} className={'mb-2'}>
                  <CCol>
                    <CAlert color={!reward.transferred ? 'info' : 'success'}>
                      <strong> Round: {reward.round.name.toUpperCase()}</strong>
                      <br />
                      <strong> Reward: {reward.name.toUpperCase()}</strong>
                      <br />
                      <strong> Prize: ₹{reward.prize}</strong>
                      <br />
                      {reward.transferred === true && (
                        <>
                          <strong>Payment screenshot: </strong>
                          <br />
                          {reward.screenshot ? (
                            <>
                              <img
                                className={'pt-2 pb-2'}
                                src={reward.screenshot}
                                alt='reward-screenshot'
                                style={{ maxWidth: '100%' }}
                              />
                              <br />
                            </>
                          ) : (
                            'N/A'
                          )}
                          <strong>
                            Transaction Details :{' '}
                            {reward.transaction_details
                              ? reward.transaction_details
                              : 'N/A'}
                          </strong>
                          <br />
                        </>
                      )}

                      {reward.claimed === false ? (
                        <CButton
                          color='dark'
                          className='btn-sm pl-4 pr-4'
                          onClick={(e) => {
                            e.preventDefault();
                            updateReward(reward.id);
                          }}
                        >
                          Claim Reward
                        </CButton>
                      ) : (
                        [
                          reward.transferred === false
                            ? 'Your Reward claim is under process'
                            : '',
                        ]
                      )}
                      {/* {
                          (reward.claimed && !reward.transferred) &&
                          <strong>Processing...</strong>
                        } */}
                    </CAlert>
                  </CCol>
                </CRow>
              );
            })
          )}
        </CCol>
      </CRow>
    </>
  );
}

export default withRouter(Rewards);
