/* eslint-disable no-unused-vars */
import {
  CButton,
  CCol,
  CContainer,
  CFormGroup,
  CLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CRow,
  CSelect,
  CSpinner,
} from '@coreui/react';
import React, { useEffect, useRef, useState } from 'react';
import AuthManager from '../../../utils/AuthManager';
import Backend from '../../../utils/Backend';
import Notify from '../../../utils/Notify';
import firebase from 'firebase/app';
require('firebase/analytics');
export default function BuyCoinModal(props) {
  const coinRef = useRef();
  const [coins, setCoins] = useState([]);
  const [loading, setLoading] = useState(false);

  const logPurchaseCoin = async (data) => {
    firebase.analytics().logEvent('purchase', data);
  };

  const buyCoins = async () => {
    if (!coinRef.current.value) {
      Notify.error('Select Coins');
      return;
    }

    try {
      const orderResponse = await Backend.createOrder({
        rupees: coins[coinRef.current.value].rupees,
        coins: coins[coinRef.current.value].coins,
      });

      const options = {
        key: orderResponse.key_id,
        currency: orderResponse.data.currency,
        amount: orderResponse.data.amount.toString(),
        order_id: orderResponse.data.id,
        name: 'Coin Purchase',
        description: 'Rinsiak coin purchase',
        image: 'https://rinsiak.in/assets/logo.jpg',
        handler: async (response) => {
          try {
            // const transaction = await Backend.addTransaction({
            //   amount: orderResponse.amount,
            //   coins: orderResponse.coins,
            //   razorpay_payment_id: response.razorpay_payment_id,
            //   razorpay_order_id: response.razorpay_order_id,
            //   razorpay_signature: response.razorpay_signature,
            // })

            const verifyPayment = await Backend.verifyTransaction({
              id: orderResponse.transaction.id,
              order_id: orderResponse.data.id,
              razorpay_order_id: response.razorpay_order_id,
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_signature: response.razorpay_signature,
            });

            await logPurchaseCoin({
              transaction_id: response.razorpay_payment_id,
              value: orderResponse.amount,
              currency: 'INR',
              tax: parseFloat(0.18 * orderResponse.amount),
              items: `${orderResponse.coins} coins`,
            });
            props.onSuccess(orderResponse.coins);
            Notify.success('Coins added successfully.');
          } catch (error) {
            props.onClose();
            Notify.error(error.message);
          }
        },
        prefill: {
          name: AuthManager.getCurrentUser().name,
          email: AuthManager.getCurrentUser().email,
        },
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();

      paymentObject.on('payment.failed', (response) => {
        props.onClose();
        Notify.error('Payment failed, Please try again');
      });
    } catch (error) {
      props.onClose();
      Notify.error(error.message);
    }
  };

  useEffect(() => {
    if (Array.isArray(props.coins)) {
      setCoins([
        // eslint-disable-next-line array-callback-return
        ...props.coins.map((coin) => {
          let coinArray = coin.split(':');
          if (Array.isArray(coinArray) && coinArray.length === 2) {
            return {
              rupees: parseInt(coinArray[0]),
              coins: parseInt(coinArray[1]),
            };
          }
        }),
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CModal
      show={props.show}
      closeOnBackdrop={false}
      centered={true}
      onClose={() => props.onClose()}
    >
      <CModalBody>
        <CContainer fluid>
          <CRow>
            <CCol>
              <CFormGroup>
                <CLabel>Select Coins</CLabel>
                <CSelect innerRef={coinRef}>
                  {coins.map((coin, index) => {
                    return (
                      <option key={index} value={index}>
                        {coin.coins} coins (Rs {coin.rupees})
                      </option>
                    );
                  })}
                </CSelect>
              </CFormGroup>
            </CCol>
          </CRow>
        </CContainer>
      </CModalBody>
      <CModalFooter>
        <CButton
          disabled={loading}
          variant='outline'
          color='dark'
          className='btn-sm pl-4 pr-4'
          onClick={() => props.onClose()}
        >
          CANCEL
        </CButton>
        <CButton
          color={'success'}
          className='btn-sm pl-4 pr-4'
          onClick={() => {
            buyCoins();
          }}
        >
          {loading ? (
            <>
              <CSpinner component='span' size='sm' aria-hidden='true' />{' '}
              Processing...
            </>
          ) : (
            <>BUY COINS</>
          )}
        </CButton>
      </CModalFooter>
    </CModal>
  );
}
