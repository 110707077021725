import {
  CAlert,
  CButton,
  CCol,
  CContainer,
  CForm,
  CFormGroup,
  CInput,
  CLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CSpinner,
} from '@coreui/react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Backend from '../../../utils/Backend';
import Notify from '../../../utils/Notify';
import CustomDatePicker from '../../components/CustomDatePicker';

export default function LeagueModal(props) {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [league, setLeague] = useState({
    id: '',
    name: '',
    start: '',
    end: '',
  });

  function handleFormChange(evt) {
    setError('');
    let name = evt.target.name;
    let value = evt.target.value;
    setLeague({ ...league, [name]: value });
  }

  function validateData() {
    if (!league.name) {
      setError('Enter league name');
      return false;
    } else if (!league.start) {
      setError('Select league start date');
      return false;
    } else if (!league.end) {
      setError('Select league end date');
      return false;
    }

    return true;
  }

  async function handleSubmit() {
    setLoading(true);

    if (!validateData()) {
      setLoading(false);
      return;
    }

    try {
      if (!props.league) {
        const response = await Backend.addLeague(league);
        props.onAdded(response.data);
        Notify.success('League added successfully.');
      } else {
        const response = await Backend.updateLeague(league.id, league);
        props.onUpdated(response.data);
        Notify.success('League updated successfully.');
      }
      props.onClose();
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  }

  useEffect(() => {
    if (props.league) {
      setLeague(props.league);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <CModal show={props.show} centered={true} onClose={() => props.onClose()}>
        <CModalHeader closeButton>
          <CModalTitle>{props.league ? 'Add' : 'Edit'} League</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CContainer fluid>
            <CRow>
              {error && (
                <CAlert color='danger' className='w-100'>
                  {error}
                </CAlert>
              )}
              <CCol sm='12'>
                <CForm
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                  }}
                >
                  <CFormGroup>
                    <CLabel>League Name</CLabel>
                    <CInput
                      value={league.name}
                      type='text'
                      name='name'
                      placeholder='Enter League Name'
                      onChange={(e) => handleFormChange(e)}
                    />
                  </CFormGroup>
                  <CFormGroup>
                    <CustomDatePicker
                      label={'Select start date'}
                      value={
                        props.league
                          ? moment(props.league.start).format('DD/MM/YYYY')
                          : ''
                      }
                      onApply={(date) => {
                        setError('');
                        setLeague({
                          ...league,
                          start: moment(date).format('YYYY-MM-DD'),
                        });
                      }}
                    />
                  </CFormGroup>
                  <CFormGroup>
                    <CustomDatePicker
                      label={'Select end date'}
                      value={
                        props.league
                          ? moment(props.league.end).format('DD/MM/YYYY')
                          : ''
                      }
                      onApply={(date) => {
                        setError('');
                        setLeague({
                          ...league,
                          end: moment(date).format('YYYY-MM-DD'),
                        });
                      }}
                    />
                  </CFormGroup>
                </CForm>
              </CCol>
            </CRow>
          </CContainer>
        </CModalBody>
        <CModalFooter>
          <CButton
            variant='outline'
            color='danger'
            className='btn-sm pl-4 pr-4'
            disabled={loading}
            onClick={() => props.onClose()}
          >
            Cancel
          </CButton>
          <CButton
            color='dark'
            className='btn-sm pl-4 pr-4'
            disabled={loading}
            onClick={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            {loading ? (
              <>
                <CSpinner component='span' size='sm' aria-hidden='true' />{' '}
                Saving...
              </>
            ) : props.league ? (
              'Update League'
            ) : (
              'Add League'
            )}
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  );
}
