import {
  CBadge,
  CHeader,
  CHeaderBrand,
  CHeaderNav,
  CToggler,
} from '@coreui/react';
import React from 'react';
import { FaSignInAlt } from 'react-icons/fa';
import { Link, withRouter } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import AuthManager from '../../utils/AuthManager';

function Header(props) {
  const { sidebarShow, setToggleSidebar, balance } = useAuth();

  // function toggleSidebar() {
  //   const val = [true, 'responsive'].includes(sidebarShow)
  //     ? false
  //     : 'responsive';
  //   setToggleSidebar(val);
  // }

  function toggleSidebarMobile() {
    const val = [false, 'responsive'].includes(sidebarShow)
      ? true
      : 'responsive';
    setToggleSidebar(val);
  }

  // async function handleLogout() {
  //   await AuthManager.logout();
  //   setCurrentUser(null);
  //   window.location = '/login';
  // }

  return (
    <CHeader withSubheader>
      <CToggler
        inHeader
        className='ml-md-3 d-lg-none'
        onClick={() => toggleSidebarMobile()}
      />
      {/* <CToggler
        inHeader
        className="ml-3 d-md-down-none"
        onClick={() => toggleSidebar()}
      /> */}

      <CHeaderBrand className='mx-auto d-lg-none' to='/'>
        <img
          src={'../assets/rinsiak-only-black.jpg'}
          alt='rinsiak-only-black'
          style={{ maxHeight: '25px' }}
        />
      </CHeaderBrand>

      <CHeaderNav className='px-3'>
        {AuthManager.getCurrentUser() ? (
          <>
            <CBadge
              className={'wallet-badge'}
              style={{ minWidth: '100px' }}
              onClick={() => {
                props.history.push('/wallet');
              }}
            >
              <span className={'coin-badge'}></span>
              {balance}
            </CBadge>
          </>
        ) : (
          <>
            <FaSignInAlt className={'mr-1'} color={'#000'} />
            <Link to={'/login'} className={'login-link'}>
              Login
            </Link>
          </>
        )}
      </CHeaderNav>
    </CHeader>
  );
}

export default withRouter(Header);
