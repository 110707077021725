import {
  CAlert,
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CSelect,
} from '@coreui/react';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import renderHTML from 'react-render-html';
import { withRouter } from 'react-router-dom';
import { defaultConfirmOptions } from '../../constants/Options';
import { useAuth } from '../../contexts/AuthContext';
import AuthManager from '../../utils/AuthManager';
import Backend from '../../utils/Backend';
import Notify from '../../utils/Notify';
import ConfirmationModal from './modals/ConfirmationModal';
import firebase from 'firebase/app';
// import { fs } from '../../firebase';
import AccountErrorModal from './modals/AccountErrorModal';
require('firebase/analytics');

function Home(props) {
  const { balance, setBalance } = useAuth();
  const leagueRef = useRef(null);
  const roundRef = useRef(null);
  const [leagues, setLeagues] = useState([]);
  const [rounds, setRounds] = useState([]);
  const [selectedRound, setSelectedRound] = useState(null);
  const [gameWeeks, setGameWeeks] = useState([]);
  const [selectedGameWeek, setSelectedGameWeek] = useState(null);
  const [matches, setMatches] = useState([]);
  const [initialMatches, setInitialMatches] = useState([]);
  const [userRounds, setUserRounds] = useState([]);
  const [userPredictions, setUserPredictions] = useState([]);
  const [entryFee, setEntryFee] = useState(0);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [confirmOptions, setConfirmOptions] = useState(defaultConfirmOptions);

  const handleLeagueChange = (e) => {
    resetLeague();

    leagues.forEach((league) => {
      if (Number(e.target.value) === league.id) {
        fetchRounds(league);
      }
    });
  };

  const handleRoundChange = (e) => {
    resetRound();
    rounds.forEach((round) => {
      if (Number(e.target.value) === round.id) {
        fetchMatches(round);
      }
    });
  };

  const fetchRounds = (league) => {
    setRounds([...league.rounds]);
    if (league.rounds.length) {
      let activeRound = league.rounds[0];
      league.rounds.forEach((element) => {
        if (element.active) {
          activeRound = element;
        }
      });
      fetchMatches(activeRound);
    }
  };

  const fetchMatches = (round) => {
    setSelectedRound(round);
    setGameWeeks([...round.game_weeks]);

    if (round.game_weeks.length) {
      setSelectedGameWeek(round.game_weeks[0]);
      setInitialMatches([...round.game_weeks[0].matches]);
      setMatches([...round.game_weeks[0].matches]);
    }
  };

  const handleWeekChange = (week) => {
    // console.log(week.matches)
    setSelectedGameWeek(week);
    setMatches([...week.matches]);
    setInitialMatches([...week.matches]);
  };

  const resetLeague = () => {
    setSelectedRound(null);
    setRounds([]);
    setGameWeeks([]);
    setSelectedGameWeek(null);
    setMatches([]);
  };

  const resetRound = () => {
    setGameWeeks([]);
    setSelectedGameWeek(null);
    setMatches([]);
  };

  const updateScore = (matchId, teamScore, action) => {
    let updateMatches = matches.map((item) => {
      if (item.id === matchId) {
        let score = item[teamScore] == null ? 0 : item[teamScore];

        if (action === 'minus') {
          score = score === 0 ? 0 : score - 1;
        } else {
          score += 1;
        }
        return { ...item, [teamScore]: parseInt(score) };
      }

      return item;
    });
    setMatches([...updateMatches]);
  };

  const getLeagues = async () => {
    const param = '?has_active_round=1';
    try {
      let response = {};
      if (AuthManager.getCurrentUser()) {
        response = await Backend.getAuthAllLeagues(param);
      } else {
        response = await Backend.getAllLeagues(param);
      }
      if (response) {
        setLeagues([...response]);
        if (response.length) {
          fetchRounds(response[0]);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getUserRounds = async () => {
    try {
      const response = await Backend.getUserRounds();
      if (response.data) {
        setUserRounds([...response.data.map((round) => round.round_id)]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getUserPredictions = async () => {
    try {
      const response = await Backend.getUserPredictions();
      if (response.data) {
        setUserPredictions([...response.data]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const initSettings = async () => {
    try {
      const response = await Backend.getSettings();
      if (response.data) {
        response.data.forEach((setting) => {
          if (setting.name === 'round_cost_in_coins') {
            setEntryFee(setting.value);
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const buyRound = async () => {
    try {
      const response = await Backend.buyRound({
        round_id: roundRef.current.value,
        coins: entryFee,
      });

      setShowConfirmModal(false);

      if (response.data) {
        let updatedUserRounds = userRounds;
        updatedUserRounds.push(parseInt(response.data.round_id));
        setUserRounds([...updatedUserRounds]);
        setBalance(parseInt(balance) - parseInt(entryFee));

        setConfirmOptions({
          ...confirmOptions,
          title: '',
          type: '',
          buttonClass: 'success',
          content: `<h6 class="text-center">THANK YOU</h6><h2 class="text-center mt-5">TAN ANG AW</h2>`,
          confirmText: `START`,
          cancelButton: false,
          onConfirm: () => {
            setShowConfirmModal(false);
          },
        });

        firebase.analytics().logEvent('round_purchase', {
          round_id: roundRef.current.value,
          event_name: 'purchase',
        });
        setShowConfirmModal(true);
      }
    } catch (error) {
      setShowConfirmModal(false);
      console.log(error);
      Notify.error(error.message);
    }
  };

  const savePrediction = async (predictedMatch) => {
    if (!AuthManager.getCurrentUser()) {
      setConfirmOptions({
        ...confirmOptions,
        title: 'Login',
        type: 'info',
        buttonClass: 'success',
        content: `<p>Please Login or Signup to submit prediction</p>`,
        confirmText: 'Login',
        onConfirm: () => props.history.push('/login'),
      });
      setShowConfirmModal(true);
      return;
    }

    let match = matches.find((item) => item.id === predictedMatch.id && item);

    if (match !== undefined) {
      if (typeof match.score1 != 'number' || typeof match.score2 != 'number') {
        Notify.error('Enter valid value of scores');
        return;
      }

      try {
        await Backend.savePrediction({
          league_id: leagueRef.current.value,
          round_id: roundRef.current.value,
          match_id: match.id,
          game_week_id: match.game_week_id,
          score1: match.score1,
          score2: match.score2,
        });

        firebase.analytics().logEvent('predict_match', {
          match_id: match.id,
          match_name: `${match.team1} v ${match.team2}`,
        });

        const resp1 = await Backend.getUserPredictions();
        const resp2 = await Backend.getGameWeekMatches(match.game_week_id);
        setUserPredictions([...resp1.data]);
        setMatches([...resp2.data]);
        Notify.success('Prediction submitted successfully.');
      } catch (error) {
        console.log(error);
        if (error.code === 1) {
          let buttonText = balance < entryFee ? 'BUY COINS' : 'BUY ROUND';

          setConfirmOptions({
            ...confirmOptions,
            title: buttonText,
            type: '',
            buttonClass: 'success',
            content: `<h6 class="text-center">PREDICT THIS ROUND FOR</h6>
                      <h2 class="text-center mt-5">${entryFee} COINS</h2>
                      <p class="text-center mt-3">you have <span class="site-title">${balance} Coins</span> in your account</p>`,
            confirmText: `${buttonText}`,
            onConfirm: () => {
              if (balance < entryFee) {
                props.history.push('/wallet');
              } else {
                buyRound();
              }
            },
          });
          setShowConfirmModal(true);
        } else {
          Notify.error(error.message);
        }
      }
    }
  };

  useEffect(() => {
    getLeagues();
    initSettings();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const comments = async () => {
  //   const commentRef = await fs.collectionGroup('comments')
  //     .where('createdAt', '>', moment('01-08-2021', 'DD-MM-YYYY').toDate()).get()

  //   commentRef.docs.forEach(comment => {
  //     let data = comment.data()
  //     if (data.author_id == 'IHnXAu1kAeTD9joGfazsjTTTKAO2') {
  //       console.log(data.author_id, moment(data.createdAt.toDate()).format('DD/MM/YYYY hh:mm'))
  //     } else {
  //       // if (comment.data().authorId !== undefined){
  //       // console.log(data.author_id, moment(data.createdAt.toDate()).format('DD/MM/YYYY'))

  //       // }
  //     }
  //   })
  // }

  useEffect(() => {
    if (AuthManager.getCurrentUser()) {
      getUserRounds();
      getUserPredictions();
    }

    return () => {};
  }, []);

  return (
    <>
      <CRow className={'pt-3 pb-3 league-select-container'}>
        <CCol className={'col-7 pr-0'}>
          <CSelect
            className={''}
            innerRef={leagueRef}
            onChange={(e) => {
              handleLeagueChange(e);
            }}
          >
            {leagues.map((league) => {
              return (
                <option value={league.id} key={league.id}>
                  {league.name}
                </option>
              );
            })}
          </CSelect>
        </CCol>
        <CCol className={'col-5'}>
          <CSelect
            value={selectedRound ? selectedRound.id : ''}
            innerRef={roundRef}
            onChange={(e) => {
              handleRoundChange(e);
            }}
          >
            {/* <option value="">Select Round</option> */}
            {rounds.map((round) => {
              return (
                <option value={round.id} key={round.id}>
                  {round.name}
                </option>
              );
            })}
          </CSelect>
        </CCol>
      </CRow>
      {selectedRound &&
        selectedRound.result !== 'announced' &&
        !userRounds.includes(
          parseInt(selectedRound ? selectedRound.id : ''),
        ) && (
          <CRow className={'pb-2 pt-2 buy-round-container'}>
            <CCol className={'col-12 text-center'}>
              <CButton
                className={'inkhel-btn-success form-control'}
                onClick={() => {
                  if (!roundRef.current.value) {
                    return;
                  }

                  if (!AuthManager.getCurrentUser()) {
                    setConfirmOptions({
                      ...confirmOptions,
                      title: 'Login',
                      type: 'info',
                      buttonClass: 'info',
                      content: `<h6>You have to Login/Signup to buy Round</h6>`,
                      confirmText: 'Login',
                      onConfirm: () => props.history.push('/login'),
                    });
                    setShowConfirmModal(true);
                    return;
                  }

                  let buttonText =
                    balance < entryFee ? 'BUY COINS' : 'BUY ROUND';

                  setConfirmOptions({
                    ...confirmOptions,
                    title: buttonText,
                    type: '',
                    buttonClass: 'success',
                    content: `<h4 class="text-center">PREDICT THIS ROUND FOR</h4>
                      <h2 class="text-center mt-3">${entryFee} COINS</h2>
                      <p style="color:red;" class="text-center">Coins ${entryFee} hian Round 1 (Thla khat) chhung prediction i nei thei ang.</p>
                      <p class="text-center mt-3">You have <span class="site-title">${balance} Coins</span> in your account</p>`,
                    confirmText: `${buttonText}`,
                    onConfirm: () => {
                      if (balance < entryFee) {
                        props.history.push('/wallet');
                      } else {
                        buyRound();
                      }
                    },
                  });
                  setShowConfirmModal(true);
                }}
              >
                PAY ENTRY FEE
              </CButton>
            </CCol>
          </CRow>
        )}

      {selectedGameWeek != null && gameWeeks.length && (
        <CRow className={'pt-0 pb-0 game-week-wrap'}>
          {gameWeeks.map((gameWeek) => {
            return (
              // <CCol className={'text-center'}>
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <a
                key={`gameweek_${gameWeek.id}`}
                className={
                  gameWeek.id === selectedGameWeek.id ? 'active' : 'inactive'
                }
                onClick={() => handleWeekChange(gameWeek)}
              >
                {gameWeek.name.toUpperCase()}
              </a>
              // </CCol>
            );
          })}
        </CRow>
      )}

      {matches.map((match, i) => {
        let predicted = userPredictions.find((prediction) => {
          if (match.predictionIds.includes(prediction.id)) {
            return match.id === prediction.match_id;
          }
          return false;
        });
        let canPredict =
          moment().isSameOrAfter(moment(match.prediction_start_at)) &&
          moment().isBefore(moment(match.start_at)) &&
          predicted === undefined &&
          match.result_announce_at == null;
        let upcomingMatch =
          moment().isBefore(moment(match.prediction_start_at)) &&
          moment().isBefore(moment(match.start_at)) &&
          match.result_announce_at == null;
        let pastMatch =
          moment().isSameOrAfter(moment(match.start_at)) ||
          match.result_announce_at != null;
        let resultAnnounced = match.result_announce_at != null;
        let headerTitle = '';
        let headerStatusClass = 'match-header-blue';

        // console.log(canPredict)
        // console.log(match.predictionIds)
        // console.log(userPredictions)
        // if(predicted){
        //   console.log(predicted.score1, predicted.score2)
        // }
        // console.log('----------------------------')

        // let predictedScore1 = '&nbsp;';
        // let predictedScore2 = '&nbsp;';
        // if (predicted) {
        //   predictedScore1 = predicted.score1;
        //   predictedScore2 = predicted.score2;
        // }

        if (upcomingMatch) {
          headerTitle = `Opening at ${moment(match.prediction_start_at).format(
            'DD/MM/YYYY hh:mmA',
          )}`;
        }

        if (canPredict || predicted !== undefined) {
          headerTitle = `Closing at ${moment(match.start_at).format(
            'DD/MM/YYYY hh:mmA',
          )}`;
        }

        if (pastMatch) {
          headerTitle = 'Closed';
        }

        if (predicted !== undefined && resultAnnounced) {
          if (predicted.points === 6) {
            headerStatusClass = 'score-predict';
          } else if (predicted.points === 1) {
            headerStatusClass = 'winner-predict';
          } else {
            headerStatusClass = 'lose-predict';
          }
        }

        if (predicted !== undefined && !resultAnnounced) {
          headerStatusClass = 'submitted-predict';
        }
        if (canPredict) {
          headerStatusClass = 'can-predict';
        }

        return (
          <div key={i}>
            <CCard className={'mt-2 mb-3 br-0 box-shadow match-card'}>
              <CCardHeader
                className={''}
                style={{ padding: 0, border: 'none' }}
              >
                <CRow className={'mx-0 my-0'}>
                  {upcomingMatch ? (
                    <CCol
                      className={
                        'col-12 py-1 match-header match-header-upcoming'
                      }
                    >
                      {headerTitle}
                    </CCol>
                  ) : (
                    <>
                      <CCol
                        className={'col-8 py-1 match-header match-header-blue'}
                      >
                        {headerTitle}
                      </CCol>
                      <CCol
                        className={`text-center match-header py-1 px-0 ${headerStatusClass} text-bold`}
                      >
                        {predicted !== undefined && resultAnnounced && (
                          <span>Match Score: {predicted.points}</span>
                        )}

                        {predicted !== undefined && !resultAnnounced && (
                          <span>SUBMITTED</span>
                        )}

                        {canPredict && (
                          <span
                            className={'submit-btn'}
                            onClick={() => {
                              savePrediction(match);
                            }}
                          >
                            SUBMIT
                          </span>
                        )}
                      </CCol>
                    </>
                  )}
                </CRow>
              </CCardHeader>
              <CCardBody className={'px-0 py-0'}>
                <CRow className={'mx-0 my-0 '}>
                  <CCol
                    className={'col-3 text-bold text-center px-0 py-2 name-col'}
                  >
                    {match.team1.toUpperCase()}
                  </CCol>
                  <CCol className={'col-2 px-0'}>
                    <CRow className={''}>
                      <CCol
                        className={
                          'text-center text-bold py-0 px-1 score-action'
                        }
                      >
                        <div
                          onClick={() => {
                            if (canPredict) {
                              updateScore(match.id, 'score1', 'minus');
                            }
                          }}
                          className={!canPredict ? 'disabled' : ''}
                        >
                          -
                        </div>
                      </CCol>
                      <CCol
                        className={'text-center text-bold py-2 px-1 score-col'}
                      >
                        {predicted !== undefined ? (
                          <span className={'match-predict'}>
                            {predicted.score1}
                          </span>
                        ) : (
                          <span
                            className={
                              !resultAnnounced && canPredict
                                ? 'match-predict-big match-predict vertical-center'
                                : 'match-predict'
                            }
                          >
                            {canPredict ? <>{match.score1}</> : <>&nbsp;</>}
                          </span>
                        )}
                        {resultAnnounced && (
                          <span className={'match-score'}>
                            {initialMatches[i].score1}
                          </span>
                        )}
                      </CCol>
                      <CCol
                        className={
                          'text-center text-bold py-0 px-1 score-action'
                        }
                      >
                        <div
                          onClick={() => {
                            if (canPredict) {
                              updateScore(match.id, 'score1', 'plus');
                            }
                          }}
                          className={!canPredict ? 'disabled' : ''}
                        >
                          +
                        </div>
                      </CCol>
                    </CRow>
                  </CCol>
                  <CCol className={'col-2 vs-col text-center px-0 py-2 vs-col'}>
                    v
                  </CCol>
                  <CCol className={'col-2 px-0'}>
                    <CRow className={''}>
                      <CCol
                        className={
                          'text-center text-bold py-2 px-1 score-action'
                        }
                      >
                        <div
                          onClick={() => {
                            if (canPredict) {
                              updateScore(match.id, 'score2', 'minus');
                            }
                          }}
                          className={!canPredict ? 'disabled' : ''}
                        >
                          -
                        </div>
                      </CCol>
                      <CCol
                        className={'text-center text-bold py-2 px-1 score-col'}
                      >
                        {predicted !== undefined ? (
                          <span className={'match-predict'}>
                            {predicted.score2}
                          </span>
                        ) : (
                          <span
                            className={
                              !resultAnnounced && canPredict
                                ? 'match-predict-big match-predict vertical-center'
                                : 'match-predict'
                            }
                          >
                            {canPredict ? <>{match.score2}</> : <>&nbsp;</>}
                          </span>
                        )}
                        {resultAnnounced && (
                          <span className={'match-score'}>
                            {initialMatches[i].score2}
                          </span>
                        )}
                      </CCol>
                      <CCol
                        className={
                          'text-center text-bold py-2 px-1 score-action'
                        }
                      >
                        <div
                          onClick={() => {
                            if (canPredict) {
                              updateScore(match.id, 'score2', 'plus');
                            }
                          }}
                          className={!canPredict ? 'disabled' : ''}
                        >
                          +
                        </div>
                      </CCol>
                    </CRow>
                  </CCol>
                  <CCol
                    className={'col-3 text-bold text-center px-0 py-2 name-col'}
                  >
                    {match.team2.toUpperCase()}
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
          </div>
        );
      })}

      {showConfirmModal && (
        <ConfirmationModal
          show={showConfirmModal}
          title={confirmOptions.title}
          content={() => (
            <>
              <CAlert
                color={confirmOptions.type ? confirmOptions.type : ''}
                className='w-100'
              >
                {renderHTML(confirmOptions.content)}
              </CAlert>
            </>
          )}
          onClose={() => {
            setShowConfirmModal(false);
          }}
          buttonClass={confirmOptions.buttonClass}
          confirmText={confirmOptions.confirmText}
          onConfirm={confirmOptions.onConfirm}
          cancelButton={confirmOptions.cancelButton}
        />
      )}

      {AuthManager.getCurrentUser() &&
        !AuthManager.getCurrentUser().account_no && <AccountErrorModal />}
    </>
  );
}

export default withRouter(Home);
