import { CIcon } from '@coreui/icons-react';
import {
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CRow,
  CSelect,
  CTooltip,
} from '@coreui/react';
import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import Backend from '../../utils/Backend';
import General from '../../utils/General';
import Loading from '../components/Loading';
import RewardPaymentModal from './modals/RewardPaymentModal';

function Rewards() {
  // eslint-disable-next-line no-unused-vars
  const [leagueId, setLeagueId] = useState(null);
  const [roundId, setRoundId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [leagues, setLeagues] = useState([]);
  const [rounds, setRounds] = useState([]);
  const [roundRewardees, setRoundRewardees] = useState([]);
  const [selectedRewardee, setSelectedRewardee] = useState(null);
  const [showPaymentModal, setShowPaymentModal] = useState(false);

  const getRoundRewards = async () => {
    try {
      const response = await Backend.getRoundRewards(roundId);
      setRoundRewardees([...response.data]);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleLeagueChange = (e) => {
    setRounds([]);
    const league = leagues.find(
      (league) => league.id === Number(e.target.value),
    );
    setLeagueId(league.id);
    setRounds([...league.rounds]);
    if (league.rounds.length) {
      setRoundId(league.rounds[0].id);
    }
  };

  const handleRoundChange = (e) => {
    setRoundId(Number(e.target.value));
  };

  const getLeagues = async () => {
    const param = '?has_active_round=0';
    try {
      const response = await Backend.getAllLeagues(param);
      if (response) {
        setLeagues([...response]);
        if (response.length) {
          setRounds([...response[0].rounds]);
          setLeagueId(response[0].id);
          if (response[0].rounds.length) {
            setRoundId(response[0].rounds[0].id);
          }
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    getLeagues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (roundId) {
      getRoundRewards();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roundId]);

  return (
    <>
      <CRow>
        <CCol>
          <h4>Rewards</h4>
        </CCol>
      </CRow>

      <CRow className={'mt-3'}>
        <CCol xs='12' lg='12'>
          <CCard>
            <CCardHeader className={'pt-3'}>
              <CRow>
                <CCol className={'col-8 pr-0'}>
                  <CSelect
                    onChange={(e) => {
                      handleLeagueChange(e);
                    }}
                  >
                    {leagues.map((league) => {
                      return (
                        <option key={league.id} value={league.id}>
                          {league.name}
                        </option>
                      );
                    })}
                  </CSelect>
                </CCol>
                <CCol className={'col-4'}>
                  <CSelect
                    onChange={(e) => {
                      handleRoundChange(e);
                      // getRoundRewards();
                    }}
                  >
                    {rounds.map((round) => {
                      return (
                        <option key={round.id} value={round.id}>
                          {round.name}
                        </option>
                      );
                    })}
                  </CSelect>
                </CCol>
              </CRow>
            </CCardHeader>
            <CCardBody>
              {loading ? (
                <Loading />
              ) : (
                <>
                  <CDataTable
                    items={roundRewardees}
                    striped
                    outlined={true}
                    fields={[
                      {
                        key: 'position',
                        label: '#',
                      },
                      {
                        key: 'username',
                        label: 'Name',
                      },
                      {
                        key: 'email',
                        label: 'Email',
                      },
                      {
                        key: 'name',
                        label: 'Reward',
                      },
                      {
                        key: 'prize',
                        label: 'Prize',
                      },
                      {
                        key: 'claimed',
                        label: 'Claimed',
                      },
                      {
                        key: 'transferred',
                        label: 'Transferred',
                      },
                      {
                        key: 'transaction_details',
                        label: 'Details',
                      },
                      {
                        key: 'actions',
                        label: 'Actions',
                      },
                    ]}
                    scopedSlots={{
                      position: (item, index) => {
                        return <td>{index + 1}</td>;
                      },
                      prize: (item) => {
                        return <td>{General.formatRupees(item.prize)}</td>;
                      },
                      claimed: (item) => {
                        return <td>{item.claimed ? 'Yes' : 'No'}</td>;
                      },
                      transferred: (item) => {
                        return <td>{item.transferred ? 'Yes' : 'No'}</td>;
                      },
                      transaction_details: (item) => {
                        return (
                          <td>
                            {item.transaction_details !== null
                              ? item.transaction_details
                              : ''}
                          </td>
                        );
                      },
                      actions: (item) => {
                        return (
                          <td>
                            <CButtonGroup className={'btn-sm'}>
                              <CTooltip
                                content='Payment Details'
                                placement='bottom'
                              >
                                <CButton
                                  color='success'
                                  variant='outline'
                                  className={'btn-sm'}
                                  onClick={() => {
                                    setSelectedRewardee(item);
                                    setShowPaymentModal(true);
                                  }}
                                >
                                  <CIcon name='cil-badge'></CIcon>
                                </CButton>
                              </CTooltip>
                            </CButtonGroup>
                          </td>
                        );
                      },
                    }}
                  />
                </>
              )}
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>

      {showPaymentModal && (
        <RewardPaymentModal
          show={showPaymentModal}
          userReward={selectedRewardee}
          onUpdated={(rewardee) => {
            setRoundRewardees([
              ...roundRewardees.map((roundRewardee) => {
                if (roundRewardee.id === rewardee.id) {
                  return rewardee;
                }

                return roundRewardee;
              }),
            ]);
            setShowPaymentModal(false);
            setSelectedRewardee(null);
          }}
          onClose={() => {
            setShowPaymentModal(false);
            setSelectedRewardee(null);
          }}
        />
      )}
    </>
  );
}

export default withRouter(Rewards);
