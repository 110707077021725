import { CAlert, CButton, CCol, CPagination, CRow } from '@coreui/react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import renderHTML from 'react-render-html';
import { withRouter } from 'react-router-dom';
import { defaultConfirmOptions } from '../../constants/Options';
import { useAuth } from '../../contexts/AuthContext';
import AuthManager from '../../utils/AuthManager';
import Backend from '../../utils/Backend';
import BuyCoinModal from './modals/BuyCoinModal';
import ConfirmationModal from './modals/ConfirmationModal';

function Wallet(props) {
  const { balance, setBalance } = useAuth();
  const [coins, setCoins] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showBuyCoinModal, setShowBuyCoinModal] = useState(false);
  const [confirmOptions, setConfirmOptions] = useState(defaultConfirmOptions);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);

  const initSettings = async () => {
    try {
      const response = await Backend.getSettings();
      if (response.data) {
        response.data.forEach((setting) => {
          if (setting.name === 'rupees_coins_values') {
            let values = setting.value.split(',');
            setCoins(values);
          }
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getTransactions = async () => {
    try {
      let params = `?per_page=10&page=${currentPage}`;
      const response = await Backend.getUserTransactions(params);
      if (response.data) {
        setLastPage(response.data.last_page);
        setTransactions([...response.data.data]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    initSettings();
  }, []);

  useEffect(() => {
    if (AuthManager.getCurrentUser()) {
      getTransactions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  return (
    <>
      <CRow className={'mt-2 mb-2'}>
        <CCol>
          <h5>WALLET</h5>
        </CCol>
      </CRow>

      <CRow className={'mt-2 mb-2'}>
        <CCol className={'text-center'}>
          <CAlert color={'info'}>
            <h5>
              <strong>Balance</strong>
            </h5>
            <h3>
              <strong>{balance} Coins</strong>
            </h3>
          </CAlert>
        </CCol>
      </CRow>

      <CRow className={'mt-2 mb-2'}>
        <CCol className={'col-6 text-center'}>
          <CButton
            className={'wallet-btn form-control'}
            onClick={() => props.history.push('rewards')}
          >
            CLAIM REWARD
          </CButton>
        </CCol>
        <CCol className={'col-6 text-center'}>
          <CButton
            className={'wallet-btn form-control'}
            onClick={() => {
              if (!AuthManager.getCurrentUser()) {
                setConfirmOptions({
                  ...confirmOptions,
                  title: 'Login',
                  type: 'info',
                  buttonClass: 'success',
                  content: `<h6>You have to Login/Register to add Coins</h6>`,
                  confirmText: 'Login',
                  onConfirm: () => props.history.push('/login'),
                });
                setShowConfirmModal(true);
                return;
              }

              setShowBuyCoinModal(true);
            }}
          >
            ADD MORE COINS
          </CButton>
        </CCol>
      </CRow>

      <CRow className={'mt-4  mb-3'}>
        <CCol className={'col-12 text-left'}>
          <h6 className={'color-grey'}>TRANSACTIONS</h6>
        </CCol>
      </CRow>

      {transactions.map((transaction) => {
        return (
          <CRow key={transaction.id} className={'mt-2 mb-4'}>
            <CCol className={'col-6 text-left'}>
              <strong>
                {moment(transaction.created_at).format('DD.MM.YYYY')}
              </strong>
              <br />
              <strong className={'color-grey'}>
                Ref: {transaction.razorpay_payment_id}
              </strong>
            </CCol>
            <CCol className={'col-4 text-right'}>
              <strong>{transaction.coins} coins</strong>
              <br />
              <strong className={'color-grey'}>Rs {transaction.amount}</strong>
            </CCol>
            <CCol className={'col-2 text-right'}>
              <strong>{transaction.status}</strong>
            </CCol>
          </CRow>
        );
      })}

      {transactions.length > 0 && (
        <CRow className={'text-center mt-3'}>
          <CCol>
            <CPagination
              className={'mt-2'}
              activePage={currentPage}
              pages={lastPage}
              onActivePageChange={(i) => setCurrentPage(i)}
            ></CPagination>
          </CCol>
        </CRow>
      )}

      {showConfirmModal && (
        <ConfirmationModal
          show={showConfirmModal}
          title={confirmOptions.title}
          content={() => (
            <>
              <CAlert
                color={confirmOptions.type ? confirmOptions.type : ''}
                className='w-100'
              >
                {renderHTML(confirmOptions.content)}
              </CAlert>
            </>
          )}
          onClose={() => {
            setShowConfirmModal(false);
          }}
          buttonClass={confirmOptions.buttonClass}
          confirmText={confirmOptions.confirmText}
          cancelButton={confirmOptions.cancelButton}
          onConfirm={confirmOptions.onConfirm}
        />
      )}

      {showBuyCoinModal && (
        <BuyCoinModal
          show={showBuyCoinModal}
          coins={coins}
          onSuccess={(coins) => {
            let newBalance = parseInt(coins) + parseInt(balance);
            getTransactions();
            setBalance(newBalance);
            setShowBuyCoinModal(false);
          }}
          onClose={() => {
            setShowBuyCoinModal(false);
          }}
        />
      )}
    </>
  );
}

export default withRouter(Wallet);
