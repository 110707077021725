import {
  CAlert,
  CButton,
  CContainer,
  CForm,
  CFormGroup,
  CInput,
  CLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CTextarea,
} from '@coreui/react';
import React, { useEffect, useState } from 'react';
import Backend from '../../../utils/Backend';
import Notify from '../../../utils/Notify';

export default function PageModal(props) {
  const pageModel = {
    id: '',
    title: '',
    content: '',
  };
  const [page, setPage] = useState(pageModel);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  async function handleAddPage() {
    setError('');
    if (!page.title) {
      setError('Please enter Page title');
      return;
    }

    setLoading(true);

    try {
      let message = 'Page added successfully.';

      if (props.page) {
        await Backend.updatePage(page.id, page);
        message = 'Page updated successfully.';
        props.onUpdated(page);
      } else {
        const response = await Backend.addPage(page);
        props.onAdded(response.data);
      }

      props.onClose();
      Notify.success(message);
    } catch (error) {
      console.log(error);
      setError(error.message);
      setLoading(false);
    }
  }

  useEffect(() => {
    if (props.page) {
      setPage(props.page);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CModal
      size={'lg'}
      show={props.show}
      centered={false}
      onClose={() => props.onClose()}
      className='border-dark'
    >
      <CModalHeader closeButton>
        <CModalTitle>{props.page ? 'Edit' : 'Add'} Page</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CContainer fluid>
          <CRow>
            {error && (
              <CAlert color='danger' className='w-100'>
                {error}
              </CAlert>
            )}
            <CForm
              className='w-100'
              onSubmit={(e) => {
                e.preventDefault();
                handleAddPage();
              }}
            >
              <CFormGroup className='w-100'>
                <CLabel>Page Title</CLabel>
                <CInput
                  className='w-100'
                  value={page.title}
                  type='text'
                  placeholder='Enter page title'
                  onChange={(e) => setPage({ ...page, title: e.target.value })}
                />
              </CFormGroup>
              <CFormGroup className='w-100'>
                <CLabel>Page Content</CLabel>
                <CTextarea
                  className='w-100'
                  value={page.content}
                  placeholder='Enter content'
                  rows={'20'}
                  onChange={(e) =>
                    setPage({ ...page, content: e.target.value })
                  }
                />
              </CFormGroup>
            </CForm>
          </CRow>
        </CContainer>
      </CModalBody>
      <CModalFooter>
        <CButton
          color='danger'
          variant='outline'
          className='btn-sm pl-2 pr-2'
          disabled={loading}
          onClick={() => props.onClose()}
        >
          Cancel
        </CButton>
        <CButton
          color='dark'
          className='btn-sm pl-4 pr-4'
          type='submit'
          disabled={loading}
          onClick={(e) => {
            e.preventDefault();
            handleAddPage();
          }}
        >
          Save
        </CButton>
      </CModalFooter>
    </CModal>
  );
}
