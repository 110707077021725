import { CAlert, CCol, CInput, CRow, CSelect } from '@coreui/react';
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import AuthManager from '../../utils/AuthManager';
import Backend from '../../utils/Backend';
import General from '../../utils/General';

function Standings() {
  const [name, setName] = useState(null);
  const [leagueId, setLeagueId] = useState(null);
  const [roundId, setRoundId] = useState(null);
  const [leagues, setLeagues] = useState([]);
  const [rounds, setRounds] = useState([]);
  const [currentUserStat, setCurrentUserStat] = useState(null);
  const [standings, setStandings] = useState([]);
  const [currentPage] = useState(1);
  const perPage = 20;

  const filterByName = General.debounce((searchKey) => {
    setName(searchKey);
  }, 300);

  const handleLeagueChange = (e) => {
    setRounds([]);
    const league = leagues.find(
      (league) => league.id === Number(e.target.value),
    );
    setLeagueId(league.id);
    setRounds([...league.rounds]);
    if (league.rounds.length) {
      setRoundId(league.rounds[0].id);
    }
  };

  const handleRoundChange = (e) => {
    setRoundId(Number(e.target.value));
    getStandings();
  };

  const getLeagues = async () => {
    const param = '?has_active_round=0';
    try {
      const response = await Backend.getAllLeagues(param);
      if (response) {
        setLeagues([...response]);
        if (response.length) {
          setRounds([...response[0].rounds]);
          setLeagueId(response[0].id);
          if (response[0].rounds.length) {
            setRoundId(response[0].rounds[0].id);
          }
        }
      }
    } catch (error) {}
  };

  const getStandings = async () => {
    try {
      let params = `?per_page=${perPage}&page=${currentPage}&round_id=${roundId}`;

      if (name) {
        params += `&name=${name}`;
      }

      const response = await Backend.getLeagueStanding(
        leagueId,
        params,
        AuthManager.getCurrentUser() ? true : false,
      );
      if (response.data) {
        setStandings([...response.data]);
        setCurrentUserStat(response.current_user);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getLeagues();
  }, []);

  useEffect(() => {
    if (roundId) {
      getStandings();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roundId, name]);

  return (
    <>
      <CRow className={'mt-2 mb-2'}>
        <CCol>
          <h5>STANDINGS</h5>
        </CCol>
      </CRow>
      <CRow className={'pt-3 pb-3 league-select-container'}>
        <CCol className={'col-8 pr-0'}>
          <CSelect
            onChange={(e) => {
              handleLeagueChange(e);
            }}
          >
            {leagues.map((league) => {
              return (
                <option key={league.id} value={league.id}>
                  {league.name}
                </option>
              );
            })}
          </CSelect>
        </CCol>
        <CCol className={'col-4'}>
          <CSelect
            onChange={(e) => {
              handleRoundChange(e);
            }}
          >
            {rounds.map((round) => {
              return (
                <option key={round.id} value={round.id}>
                  {round.name}
                </option>
              );
            })}
          </CSelect>
        </CCol>
        <CCol className={'mt-3'}>
          <CInput
            type={'text'}
            placeholder={'Search by Name'}
            onChange={(e) => {
              filterByName(e.target.value);
            }}
          />
        </CCol>
      </CRow>

      {currentUserStat && (
        <CRow className={'mt-3'}>
          <CCol>
            <CAlert color={'success'} className={'w-100'}>
              <CRow>
                <CCol className={'col-1 text-left'}>
                  <strong>{currentUserStat.position}</strong>
                </CCol>
                <CCol className={'col-7 text-left'}>
                  <strong>{currentUserStat.name}</strong>
                  <br />
                </CCol>
                <CCol className={'col-3 text-right'}>
                  <strong>
                    {currentUserStat.points}{' '}
                    {currentUserStat.points === '-' ? '' : 'points'}
                  </strong>
                </CCol>
              </CRow>
            </CAlert>
          </CCol>
        </CRow>
      )}

      <CRow>
        <CCol>
          {standings.map((standing, index) => {
            return (
              <div key={index} className={'mt-3 mb-2 w-100'}>
                <CRow>
                  <CCol className={'col-1 text-left'}>
                    <strong>{(currentPage - 1) * perPage + ++index}</strong>
                  </CCol>
                  <CCol className={'col-7 text-left'}>
                    <strong>{standing.name}</strong>
                    <br />
                    <strong>
                      <small className={'color-grey'}>
                        Account ID: {standing.provider_id}
                      </small>
                    </strong>
                  </CCol>
                  <CCol className={'col-3 text-right'}>
                    <strong>{standing.points} points</strong>
                  </CCol>
                </CRow>
              </div>
            );
          })}
        </CCol>
      </CRow>

      {/* {standings.length > 0 && (
        <CRow className={'text-center mt-3'}>
          <CCol>
            <CPagination
              className={'mt-2'}
              activePage={currentPage}
              pages={lastPage}
              onActivePageChange={(i) => setCurrentPage(i)}
            ></CPagination>
          </CCol>
        </CRow>
      )} */}
    </>
  );
}

export default withRouter(Standings);
