import FetchHelper from './FetchHelper';
import '../constants/Api';
import AuthManager from './AuthManager';

class Backend {
  static updateUser(id, data) {
    return FetchHelper.put(`${window.Api.Users}/${id}`, data, true, true);
  }

  // Settings
  static getSetting(name) {
    return FetchHelper.get(`${window.Api.Settings}/${name}`, false);
  }

  static getSettings() {
    return FetchHelper.get(`${window.Api.Settings}`, false);
  }

  static updateSettings(data) {
    return FetchHelper.post(`${window.Api.Settings}`, data, false, true);
  }

  // Leagues
  static getLeague(id) {
    return FetchHelper.get(`${window.Api.Leagues}/${id}`, false);
  }

  static getLeagues(params = '') {
    return FetchHelper.get(`${window.Api.Leagues}/paginate${params}`, false);
  }

  static getAllLeagues(params = '') {
    return FetchHelper.get(`${window.Api.Leagues}${params}`, false);
  }

  static getAuthAllLeagues(params = '') {
    return FetchHelper.get(
      `${window.Api.Base}/auth/user/leagues${params}`,
      true,
    );
  }

  static getLeagueData(id, relation = '') {
    return FetchHelper.get(`${window.Api.Leagues}/${id}/${relation}`, false);
  }

  static addLeague(data) {
    return FetchHelper.post(`${window.Api.Leagues}`, data, false, true);
  }

  static updateLeague(id, data) {
    return FetchHelper.put(`${window.Api.Leagues}/${id}`, data, true, true);
  }

  static deleteLeague(id) {
    return FetchHelper.delete(`${window.Api.Leagues}/${id}/trash`, null, true);
  }

  // Rounds
  static getRound(id) {
    return FetchHelper.get(`${window.Api.Rounds}/${id}`, false);
  }

  static getRoundDetails(id) {
    return FetchHelper.get(`${window.Api.Rounds}/${id}/details`, false);
  }

  static getRounds(params = '') {
    return FetchHelper.get(`${window.Api.Rounds}/paginate${params}`, false);
  }

  static getAllRounds() {
    return FetchHelper.get(`${window.Api.Rounds}`, false);
  }

  static addRound(data) {
    return FetchHelper.post(`${window.Api.Rounds}`, data, false, true);
  }

  static updateRound(id, data) {
    return FetchHelper.put(`${window.Api.Rounds}/${id}`, data, true, true);
  }

  static deleteRound(id) {
    return FetchHelper.delete(`${window.Api.Rounds}/${id}/trash`, null, true);
  }

  // Match
  static getMatch(id) {
    return FetchHelper.get(`${window.Api.Matches}/${id}`, false);
  }

  static getMatches(params = '') {
    return FetchHelper.get(`${window.Api.Matches}/paginate${params}`, false);
  }

  static getLeagueMatches(id) {
    return FetchHelper.get(`${window.Api.Matches}/league/${id}`, false);
  }

  static getGameWeekMatches(id) {
    return FetchHelper.get(
      `${window.Api.Matches}/game-week/${id}`,
      AuthManager.getCurrentUser() ? true : false,
    );
  }

  static getAllMatches() {
    return FetchHelper.get(`${window.Api.Matches}`, false);
  }

  static addMatch(data) {
    return FetchHelper.post(`${window.Api.Matches}`, data, false, true);
  }

  static updateMatch(id, data) {
    return FetchHelper.put(`${window.Api.Matches}/${id}`, data, true, true);
  }

  static deleteMatch(id) {
    return FetchHelper.delete(`${window.Api.Matches}/${id}/trash`, null, true);
  }

  // Game week
  static getGameWeek(id) {
    return FetchHelper.get(`${window.Api.GameWeeks}/${id}`, false);
  }
  static addGameWeek(data) {
    return FetchHelper.post(`${window.Api.GameWeeks}`, data, false, true);
  }

  static updateGameWeek(id, data) {
    return FetchHelper.put(`${window.Api.GameWeeks}/${id}`, data, true, true);
  }

  static deleteGameWeek(id) {
    return FetchHelper.delete(
      `${window.Api.GameWeeks}/${id}/trash`,
      null,
      true,
    );
  }

  // Page
  static getPage(id) {
    return FetchHelper.get(`${window.Api.Pages}/${id}`, false);
  }

  static getAllPages() {
    return FetchHelper.get(`${window.Api.Pages}`, false);
  }

  static getPages(params = '') {
    return FetchHelper.get(`${window.Api.Pages}/paginate${params}`, false);
  }

  static addPage(data) {
    return FetchHelper.post(`${window.Api.Pages}`, data, false, true);
  }

  static updatePage(id, data) {
    return FetchHelper.put(`${window.Api.Pages}/${id}`, data, true, true);
  }

  static deletePage(id) {
    return FetchHelper.delete(`${window.Api.Pages}/${id}/trash`, null, true);
  }

  // Order
  static createOrder(data = {}) {
    return FetchHelper.post(`${window.Api.Razorpay}/order`, data, false, true);
  }

  // Transactions
  static addTransaction(data = {}) {
    return FetchHelper.post(
      `${window.Api.Razorpay}/transactions`,
      data,
      false,
      true,
    );
  }

  static verifyTransaction(data = {}) {
    return FetchHelper.post(`${window.Api.Razorpay}/verify`, data, false, true);
  }

  static getAllTransactions() {
    return FetchHelper.get(`${window.Api.Transactions}`, true);
  }

  // User specific
  static getUserTransactions(params = '') {
    return FetchHelper.get(`${window.Api.Users}/transactions${params}`, true);
  }

  static getUserRounds(params = '') {
    return FetchHelper.get(`${window.Api.Users}/rounds${params}`, true);
  }

  static getUserPredictions(params = '') {
    // if (params) {
    return FetchHelper.get(`${window.Api.Users}/predictions${params}`, true);
  }

  static buyRound(data) {
    return FetchHelper.post(`${window.Api.Users}/round`, data, false, true);
  }

  // static buyRound(data) {
  //   return FetchHelper.post(`${window.Api.Users}/round`, data, false, true);
  // }

  static savePrediction(data) {
    return FetchHelper.post(`${window.Api.Predictions}`, data, false, true);
  }

  // League Standing
  static getLeagueStanding(id, params = '', auth = false) {
    if (auth) {
      return FetchHelper.get(
        `${window.Api.Base}/auth/leagues/${id}/standings${params}`,
        true,
      );
    } else {
      return FetchHelper.get(
        `${window.Api.Base}/leagues/${id}/standings${params}`,
        false,
      );
    }
  }

  //  Standing & Result
  static generateRoundResult(data) {
    return FetchHelper.post(`${window.Api.RoundResult}`, data, false, true);
  }

  static announceRoundResult(id, data) {
    return FetchHelper.post(
      `${window.Api.RoundResult}/${id}/announce`,
      data,
      false,
      true,
    );
  }

  static getRoundResults(leagueId, roundId, params = '', auth) {
    if (auth) {
      return FetchHelper.get(
        `${window.Api.Base}/auth/leagues/${leagueId}/rounds/${roundId}/results${params}`,
        true,
      );
    } else {
      return FetchHelper.get(
        `${window.Api.Base}/leagues/${leagueId}/rounds/${roundId}/results${params}`,
        false,
      );
    }
  }

  // Rewards
  static getReward(id) {
    return FetchHelper.get(`${window.Api.Rewards}/${id}`, false);
  }

  static getUserRewards() {
    return FetchHelper.get(`${window.Api.UserRewards}/`, true);
  }

  static getRewards() {
    return FetchHelper.get(`${window.Api.Rewards}`, false);
  }

  static addReward(data) {
    return FetchHelper.post(`${window.Api.Rewards}`, data, false, true);
  }

  static updateReward(id, data) {
    return FetchHelper.put(`${window.Api.Rewards}/${id}`, data, true, true);
  }

  static deleteReward(id) {
    return FetchHelper.delete(`${window.Api.Rewards}/${id}/trash`, null, true);
  }

  static getRoundRewards(id) {
    return FetchHelper.get(`${window.Api.Rounds}/${id}/rewards`, false);
  }

  // User Rewards
  static updateUserReward(id, data) {
    return FetchHelper.put(`${window.Api.UserRewards}/${id}`, data, true, true);
  }

  // Upload file
  static uploadFile(file, folder) {
    let data = new FormData();
    data.append('file', file);
    return FetchHelper.post(`${window.Api.UploadFile}/${folder}`, data, true);
  }
}

export default Backend;
