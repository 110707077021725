import { CIcon } from '@coreui/icons-react';
import {
  CAlert,
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CPagination,
  CRow,
  CTooltip,
} from '@coreui/react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { defaultConfirmOptions } from '../../constants/Options';
import Backend from '../../utils/Backend';
import Notify from '../../utils/Notify';
import Loading from '../components/Loading';
import ConfirmationModal from './modals/ConfirmationModal';
import PageModal from './modals/PageModal';

function Pages(props) {
  const [pages, setPages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedPage, setSelectedPage] = useState();
  const [showAddPage, setShowAddPage] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [confirmOptions, setConfirmOptions] = useState(defaultConfirmOptions);

  const deletePage = async (page) => {
    if (page == null) {
      setShowConfirmModal(false);
      Notify.error('Please select Page');
      return;
    }

    try {
      await Backend.deletePage(page.id);
      const updatedPages = pages.filter((item) => item.id !== page.id && item);
      setPages([...updatedPages]);
      setShowConfirmModal(false);
      setSelectedPage(null);
      Notify.success('Page deleted successfully.');
    } catch (error) {
      setShowConfirmModal(false);
      Notify.error(error.message);
    }
  };

  const getPages = async () => {
    setLoading(true);

    try {
      let params = `?per_page=10&page=${currentPage}`;
      const response = await Backend.getPages(params);
      if (response.data) {
        setLastPage(response.data.last_page);
        setPages([...response.data.data]);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      // Notify.error(error.message)
      setLoading(false);
    }
  };

  useEffect(() => {
    getPages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  return (
    <>
      <CRow>
        <CCol>
          <h4>Pages</h4>
        </CCol>
        <CCol className='text-right'>
          <CButton
            color='dark'
            className='btn-sm'
            onClick={() => setShowAddPage(!showAddPage)}
          >
            <CIcon name='cil-plus' /> Add Page
          </CButton>
        </CCol>
      </CRow>

      <CRow className={'mt-3'}>
        <CCol xs='12' lg='12'>
          <CCard>
            <CCardHeader>
              <CRow>
                <CCol>
                  <h6>Pages</h6>
                </CCol>
              </CRow>
            </CCardHeader>
            <CCardBody>
              {loading ? (
                <Loading />
              ) : (
                <>
                  <CDataTable
                    items={pages}
                    striped
                    outlined={true}
                    fields={[
                      {
                        key: 'title',
                        label: 'Title',
                      },
                      {
                        key: 'createdAt',
                        label: 'Created Date',
                      },
                      {
                        key: 'actions',
                        label: 'Actions',
                      },
                    ]}
                    scopedSlots={{
                      createdAt: (item) => {
                        return (
                          <td>{moment(item.start).format('DD/MM/YYYY')}</td>
                        );
                      },
                      actions: (item) => {
                        return (
                          <td>
                            <CButtonGroup className={'btn-sm'}>
                              <CTooltip content='Edit Page' placement='bottom'>
                                <CButton
                                  color='dark'
                                  variant='outline'
                                  className={'btn-sm'}
                                  onClick={() => {
                                    setSelectedPage(item);
                                    setShowAddPage(true);
                                  }}
                                >
                                  <CIcon name='cil-pen-alt'></CIcon>
                                </CButton>
                              </CTooltip>
                              <CTooltip
                                content='Delete Page'
                                placement='bottom'
                              >
                                <CButton
                                  color='danger'
                                  variant='outline'
                                  className={'btn-sm'}
                                  onClick={() => {
                                    setConfirmOptions({
                                      ...confirmOptions,
                                      content: `Are you sure you want to delete ${item.title}?`,
                                      onConfirm: () => deletePage(item),
                                    });
                                    setShowConfirmModal(true);
                                  }}
                                >
                                  <CIcon name='cil-trash'></CIcon>
                                </CButton>
                              </CTooltip>
                            </CButtonGroup>
                          </td>
                        );
                      },
                    }}
                  />
                  <CPagination
                    className={'mt-2'}
                    activePage={currentPage}
                    pages={lastPage}
                    onActivePageChange={(i) => setCurrentPage(i)}
                  ></CPagination>
                </>
              )}
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>

      {showAddPage && (
        <PageModal
          show={showAddPage}
          page={selectedPage}
          onAdded={(page) => {
            setSelectedPage(null);
            pages.unshift(page);
            setPages([...pages]);
          }}
          onUpdated={(page) => {
            setSelectedPage(null);
            const updatedPages = pages.map((item) => {
              if (item.id === page.id) {
                return page;
              } else {
                return item;
              }
            });
            setPages([...updatedPages]);
          }}
          onClose={() => {
            setShowAddPage(false);
            setSelectedPage(null);
          }}
        />
      )}

      {showConfirmModal && (
        <ConfirmationModal
          show={showConfirmModal}
          title={confirmOptions.title}
          content={() => (
            <>
              <CAlert color={confirmOptions.type} className='w-100'>
                {confirmOptions.content}
              </CAlert>
            </>
          )}
          onClose={() => {
            setShowConfirmModal(false);
          }}
          confirmText={confirmOptions.confirmText}
          onConfirm={confirmOptions.onConfirm}
        />
      )}
    </>
  );
}

export default withRouter(Pages);
