import {
  CButton,
  CContainer,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CSpinner,
} from '@coreui/react';
import React, { useState } from 'react';

export default function ConfirmationModal(props) {
  const [loading, setLoading] = useState(false);

  function renderContent() {
    if (!props.content) return;

    if (typeof props.content == 'function') {
      return props.content();
    }

    return props.content;
  }

  return (
    <CModal
      show={props.show}
      closeOnBackdrop={false}
      centered={true}
      onClose={() => props.onClose()}
    >
      <CModalHeader>
        <CModalTitle>{props.title}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CContainer fluid>
          <CRow>{renderContent()}</CRow>
        </CContainer>
      </CModalBody>
      <CModalFooter>
        <CButton
          disabled={loading}
          variant='outline'
          color='dark'
          className='btn-sm pl-4 pr-4'
          onClick={() => props.onClose()}
        >
          Cancel
        </CButton>
        <CButton
          color={props.buttonType ? props.buttonType : 'danger'}
          className='btn-sm pl-4 pr-4'
          onClick={() => {
            setLoading(true);
            props.onConfirm();
          }}
        >
          {loading ? (
            <>
              <CSpinner component='span' size='sm' aria-hidden='true' />{' '}
              Processing...
            </>
          ) : (
            props.confirmText
          )}
        </CButton>
      </CModalFooter>
    </CModal>
  );
}
