import AuthManager from './AuthManager';

// returns an authenticated fetch requests if possible
// these method are just shortcuts for including headers into
// fetch requests
// const PAGE_LIMIT = 20
export default class FetchHelper {
  static get(endpoint, validateTokens = true) {
    let data = {};
    data['headers'] = AuthManager.getHeaders();
    data['crossDomain'] = true;
    let statusCode = null;
    return FetchHelper._handleValidateTokens(validateTokens).then(() => {
      return fetch(endpoint, data)
        .then((response) => {
          statusCode = response.status;
          return response.json();
        })
        .then((responseJson) => {
          let status = { code: statusCode, success: responseJson.status };
          if (this._hasError(status)) {
            throw FetchHelper._getError(responseJson);
            // return
          }

          return responseJson;
        });
    });
  }

  static post(endpoint, data, isMultiPart = false, validateTokens = true) {
    let statusCode = null;
    return FetchHelper._handleValidateTokens(validateTokens).then(() => {
      return fetch(endpoint, {
        method: 'POST',
        headers: isMultiPart
          ? AuthManager.getHeaders('multipart/form-data')
          : AuthManager.getHeaders(),
        body: isMultiPart ? data : JSON.stringify(data),
      })
        .then((response) => {
          statusCode = response.status;
          if (statusCode === 204) {
            return response;
          }
          return response.json();
        })
        .then((responseJson) => {
          let status = { code: statusCode, success: responseJson.status };
          if (this._hasError(status)) {
            throw FetchHelper._getError(responseJson);
            // return
          }
          return responseJson;
        });
    });
  }

  static _handleValidateTokens(validateTokens) {
    return new Promise((resolve, reject) => {
      if (validateTokens) {
        return AuthManager.validateTokens(resolve, reject);
      }
      resolve();
    });
  }

  static patch(endpoint, data, stringify = true, validateTokens = true) {
    var headers = AuthManager.getHeaders();
    if (stringify) {
      data = JSON.stringify(data);
    } else {
      headers = AuthManager.getHeaders('multipart/form-data');
    }

    let statusCode = null;
    return FetchHelper._handleValidateTokens(validateTokens).then(() => {
      return fetch(endpoint, {
        method: 'PATCH',
        headers: headers,
        body: data,
      })
        .then((response) => {
          statusCode = response.status;
          if (statusCode === 204) {
            return response;
          }
          return response.json();
        })
        .then((responseJson) => {
          let status = { code: statusCode, success: responseJson.status };
          if (this._hasError(status)) {
            throw FetchHelper._getError(responseJson);
          }
          return responseJson;
        });
    });
  }

  static put(endpoint, data, stringify = true, validateTokens = true) {
    var headers = AuthManager.getHeaders();
    // this is needed by server side for all put requests
    data._method = 'PUT';
    if (stringify) {
      data = JSON.stringify(data);
    } else {
      headers = AuthManager.getHeaders('multipart/form-data');
    }
    let statusCode = null;
    return FetchHelper._handleValidateTokens(validateTokens).then(() => {
      return fetch(endpoint, {
        // this is needed by server side for all put requests
        method: 'PUT',
        headers: headers,
        body: data,
      })
        .then((response) => {
          statusCode = response.status;
          return response.json();
        })
        .then((responseJson) => {
          let status = { code: statusCode, success: responseJson.status };
          if (this._hasError(status)) {
            throw FetchHelper._getError(responseJson);
          }

          return responseJson;
        });
    });
  }

  static delete(endpoint, data, validateTokens = true) {
    let statusCode = null;
    return FetchHelper._handleValidateTokens(validateTokens).then(() => {
      return fetch(endpoint, {
        method: 'DELETE',
        headers: AuthManager.getHeaders(),
        body: JSON.stringify(data),
      })
        .then((response) => {
          statusCode = response.status;
          if (statusCode === 204) {
            return response;
          }
          return response.json();
        })
        .then((responseJson) => {
          let status = { code: statusCode, success: responseJson.status };
          if (this._hasError(status)) {
            throw FetchHelper._getError(responseJson);
            // return
          }
          return responseJson;
        });
    });
  }

  static _hasError({ code, success }) {
    return code < 200 || code > 299 || success === false;
  }

  static _getError(responseJson) {
    let error = responseJson.messages;
    let messages = '';

    error.forEach((message) => {
      messages += `${message} `;
    });

    return {
      error: error,
      message: messages,
      code: responseJson.error_code ? responseJson.error_code : '',
    };
  }

  static _hasMore(results, pageLimit) {
    if (results.current_page) {
      return results.current_page < results.last_page;
    }
    return results.data.length >= pageLimit;
  }
}
