import React from 'react'
import Sidebar from './Sidebar'
import Header from './Header'
import Main from './Main'
import Footer from './Footer'

export default function FrontendLayout() {
  return (
    <div className="c-app c-default-layout c-frontend-layout">
      <Sidebar />
      <div className="c-wrapper">
        <Header />
        <div className="c-body">
          {/* <div className={'ads'}>
            <img src={'../assets/zote-honda.jpg'} style={{ maxWidth: '100%' }} />
          </div> */}
          <Main />
        </div>
        <Footer />
      </div>
    </div>
  )
}
