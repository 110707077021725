import { CContainer } from '@coreui/react'
import React from 'react'
import { Switch } from 'react-router-dom'
import AdminRoute from '../../components/AdminRoute'
import Dashboard from '../../pages/backend/Dashboard'
import League from '../../pages/backend/League'
import Leagues from '../../pages/backend/Leagues'
import Pages from '../../pages/backend/Pages'
import Rewards from '../../pages/backend/Rewards'
import RewardSettings from '../../pages/backend/RewardSettings'
import Round from '../../pages/backend/Round'
import Rounds from '../../pages/backend/Rounds'
import Settings from '../../pages/backend/Settings'

export default function Main() {
  return (
    <div className="c-main">
      <CContainer fluid>
        <Switch>
          <AdminRoute exact path="/admin" component={Dashboard} />
          <AdminRoute exact path="/admin/leagues" component={Leagues} />
          <AdminRoute exact path="/admin/leagues/:leagueId" component={League} />
          <AdminRoute exact path="/admin/rounds" component={Rounds} />
          <AdminRoute exact path="/admin/rounds/:roundId" component={Round} />
          <AdminRoute exact path="/admin/rewards" component={Rewards} />
          <AdminRoute exact path="/admin/reward-settings" component={RewardSettings} />
          <AdminRoute exact path="/admin/pages" component={Pages} />
          <AdminRoute exact path="/admin/settings" component={Settings} />
        </Switch>
      </CContainer>
    </div>
  )
}
