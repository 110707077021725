import {
  CAlert,
  CButton,
  CCol,
  CContainer,
  CForm,
  CFormGroup,
  CLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CSpinner,
  CTextarea,
} from '@coreui/react';
import React, { useState } from 'react';
import Notify from '../../../utils/Notify';
import Backend from '../../../utils/Backend';
import UploadFile from '../../components/UploadFile';

export default function RewardPaymentModal(props) {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [userReward, setUserReward] = useState(props.userReward);

  function handleFormChange(evt) {
    setError('');
    let name = evt.target.name;
    let value = evt.target.value;

    setUserReward({ ...userReward, [name]: value });
  }

  function validateData() {
    if (!userReward.screenshot) {
      setError('Upload payment screenshot file');
      return false;
    }

    return true;
  }

  async function handleSubmit() {
    setLoading(true);

    if (!validateData()) {
      setLoading(false);
      return;
    }

    try {
      let updated = { ...userReward, transferred: true };
      await Backend.updateUserReward(userReward.id, updated);
      props.onUpdated(updated);
      Notify.success('User Reward details updated.');
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  }

  return (
    <>
      <CModal show={props.show} centered={true} onClose={() => props.onClose()}>
        <CModalHeader closeButton>
          <CModalTitle>User Reward</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CContainer fluid>
            <CRow>
              {error && (
                <CAlert color='danger' className='w-100'>
                  {error}
                </CAlert>
              )}
              <CCol sm='12'>
                <CForm
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                  }}
                >
                  <CFormGroup>
                    <CLabel>
                      <strong>Phone No:</strong> {userReward.phone_no}
                    </CLabel>
                  </CFormGroup>
                  <CFormGroup>
                    <CLabel>
                      <strong>Bank Details:</strong> {userReward.bank_details}
                    </CLabel>
                  </CFormGroup>
                  <CFormGroup>
                    <CLabel>
                      Details <small>(Optional)</small>
                    </CLabel>
                    <CTextarea
                      value={userReward.transaction_details}
                      name='transaction_details'
                      placeholder='Eg. Transaction ID/Bank reference number etc..'
                      rows={'2'}
                      onChange={(e) => handleFormChange(e)}
                    />
                  </CFormGroup>
                  <UploadFile
                    label={'Select Payment screenshot/Reference'}
                    buttonLabel={'Upload'}
                    folder={'screenshots'}
                    accepts={'.png, .jpeg, .jpg'}
                    onUploaded={(fileUrl) => {
                      setUserReward({ ...userReward, screenshot: fileUrl });
                    }}
                  />
                  <CFormGroup>
                    <CLabel style={{ wordBreak: 'break-all' }}>
                      Payment screenshot:{' '}
                    </CLabel>
                    {userReward.screenshot && (
                      <img
                        src={userReward.screenshot}
                        alt='reward'
                        style={{ maxWidth: '100%' }}
                      />
                    )}
                  </CFormGroup>
                </CForm>
              </CCol>
            </CRow>
          </CContainer>
        </CModalBody>
        <CModalFooter>
          <CButton
            variant='outline'
            color='danger'
            className='btn-sm pl-4 pr-4'
            disabled={loading}
            onClick={() => props.onClose()}
          >
            Cancel
          </CButton>
          <CButton
            color='dark'
            className='btn-sm pl-4 pr-4'
            disabled={loading}
            onClick={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            {loading ? (
              <>
                <CSpinner component='span' size='sm' aria-hidden='true' />{' '}
                Saving...
              </>
            ) : (
              'Save'
            )}
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  );
}
