import { CIcon } from '@coreui/icons-react';
import {
  CAlert,
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CRow,
  CTooltip,
} from '@coreui/react';
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { defaultConfirmOptions } from '../../constants/Options';
import Backend from '../../utils/Backend';
import Notify from '../../utils/Notify';
import Loading from '../components/Loading';
import ConfirmationModal from './modals/ConfirmationModal';
import RewardModal from './modals/RewardModal';

function RewardSettings(props) {
  const [loading, setLoading] = useState(true);
  const [rewards, setRewards] = useState([]);
  const [selectedReward, setSelectedReward] = useState();
  const [showAddReward, setShowAddReward] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [confirmOptions, setConfirmOptions] = useState(defaultConfirmOptions);

  const deleteReward = async (reward) => {
    if (reward == null) {
      setShowConfirmModal(false);
      Notify.error('Please select Reward');
      return;
    }

    try {
      await Backend.deleteReward(reward.id);
      const updatedRewards = rewards.filter(
        (item) => item.id !== reward.id && item,
      );
      setRewards([...updatedRewards]);
      setShowConfirmModal(false);
      setSelectedReward(null);
      Notify.success('Reward deleted successfully.');
    } catch (error) {
      setShowConfirmModal(false);
      Notify.error(error.message);
    }
  };

  const getRewards = async () => {
    setLoading(true);

    try {
      const response = await Backend.getRewards();
      if (response.data) {
        setRewards([...response.data]);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      // Notify.error(error.message)
      setLoading(false);
    }
  };

  useEffect(() => {
    getRewards();
  }, []);

  return (
    <>
      <CRow>
        <CCol>
          <h4>Reward Settings</h4>
        </CCol>
        <CCol className='text-right'>
          <CButton
            color='dark'
            className='btn-sm'
            onClick={() => setShowAddReward(!showAddReward)}
          >
            <CIcon name='cil-plus' /> Add Reward
          </CButton>
        </CCol>
      </CRow>

      <CRow className={'mt-3'}>
        <CCol xs='12' lg='12'>
          <CCard>
            <CCardHeader>
              <CRow>
                <CCol>
                  <h6>Reward Settings</h6>
                </CCol>
              </CRow>
            </CCardHeader>
            <CCardBody>
              {loading ? (
                <Loading />
              ) : (
                <>
                  <CDataTable
                    items={rewards}
                    striped
                    outlined={true}
                    fields={[
                      {
                        key: 'name',
                        label: 'Name',
                      },
                      {
                        key: 'percentage',
                        label: 'Reward Percentage (%)',
                      },
                      {
                        key: 'rank',
                        label: 'Rank',
                      },
                      {
                        key: 'positions',
                        label: 'Positions',
                      },
                      {
                        key: 'actions',
                        label: 'Actions',
                      },
                    ]}
                    scopedSlots={{
                      actions: (item) => {
                        return (
                          <td>
                            <CButtonGroup className={'btn-sm'}>
                              <CTooltip
                                content='Edit Reward'
                                placement='bottom'
                              >
                                <CButton
                                  color='dark'
                                  variant='outline'
                                  className={'btn-sm'}
                                  onClick={() => {
                                    setSelectedReward(item);
                                    setShowAddReward(true);
                                  }}
                                >
                                  <CIcon name='cil-pen-alt'></CIcon>
                                </CButton>
                              </CTooltip>
                              <CTooltip
                                content='Delete Reward'
                                placement='bottom'
                              >
                                <CButton
                                  color='danger'
                                  variant='outline'
                                  className={'btn-sm'}
                                  onClick={() => {
                                    setConfirmOptions({
                                      ...confirmOptions,
                                      content: `Are you sure you want to delete ${item.name}?`,
                                      onConfirm: () => deleteReward(item),
                                    });
                                    setShowConfirmModal(true);
                                  }}
                                >
                                  <CIcon name='cil-trash'></CIcon>
                                </CButton>
                              </CTooltip>
                            </CButtonGroup>
                          </td>
                        );
                      },
                    }}
                  />
                </>
              )}
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>

      {showAddReward && (
        <RewardModal
          show={showAddReward}
          reward={selectedReward}
          onAdded={(reward) => {
            setSelectedReward(null);
            rewards.push(reward);
            setRewards([...rewards]);
          }}
          onUpdated={(reward) => {
            setSelectedReward(null);
            const updatedRewards = rewards.map((item) => {
              if (item.id === reward.id) {
                return reward;
              } else {
                return item;
              }
            });
            setRewards([...updatedRewards]);
          }}
          onClose={() => {
            setSelectedReward(null);
            setShowAddReward(false);
          }}
        />
      )}

      {showConfirmModal && (
        <ConfirmationModal
          show={showConfirmModal}
          title={confirmOptions.title}
          content={() => (
            <>
              <CAlert color={confirmOptions.type} className='w-100'>
                {confirmOptions.content}
              </CAlert>
            </>
          )}
          onClose={() => {
            setShowConfirmModal(false);
          }}
          confirmText={confirmOptions.confirmText}
          onConfirm={confirmOptions.onConfirm}
        />
      )}
    </>
  );
}

export default withRouter(RewardSettings);
