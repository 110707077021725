import AsyncStorage from './AsyncStorage';
import FetchHelper from './FetchHelper';
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import '../constants/Api';

// var isLoggedIn = false
// var accessToken = null
// var refreshToken = null
// var currentUser = null

const KEY_USER_ID = 'userId';
const KEY_ACCESS_TOKEN = 'accessToken';
const KEY_REFRESH_TOKEN = 'refreshToken';

export default class AuthManager {
  static isAuthenticated() {
    return AuthManager.isLoggedIn;
  }

  static getAccessToken() {
    return AuthManager.accessToken;
  }

  static getCurrentUser() {
    return AuthManager.currentUser;
  }

  static _hasError(responseJson) {
    let hasError = false;
    let tokens = responseJson.tokens;

    if (!tokens) {
      hasError = true;
    }

    if (tokens.length === 0) {
      hasError = true;
    }

    if (!tokens.access) {
      hasError = true;
    }

    if (!tokens.refresh) {
      hasError = true;
    }

    return hasError;
  }

  static register(data) {
    return FetchHelper.post(window.Api.Register, data, false, false)
      .then((responseJson) => {
        if (this._hasError(responseJson)) {
          throw AuthManager.getError(responseJson);
        }
        AuthManager._updateTokens(responseJson);
        AuthManager._setUser(responseJson);
        return responseJson;
      })
      .catch((error) => {
        throw AuthManager.getError(error);
      });
  }

  static fireBaseLogin(data) {
    return FetchHelper.post(window.Api.User.FirebaseLogin, data, false, false)
      .then((responseJson) => {
        if (this._hasError(responseJson)) {
          throw AuthManager.getError(responseJson);
        }

        AuthManager._updateTokens(responseJson);
        AuthManager._setUser(responseJson);

        return responseJson;
      })
      .catch((error) => {
        throw AuthManager.getError(error);
      });
  }

  static login(name, password) {
    let data = { name, password };

    return FetchHelper.post(window.Api.User.Login, data, false, false)
      .then((responseJson) => {
        if (this._hasError(responseJson)) {
          throw AuthManager.getError(responseJson);
        }

        AuthManager._updateTokens(responseJson);
        AuthManager._setUser(responseJson);

        return responseJson;
      })
      .catch((error) => {
        throw AuthManager.getError(error);
      });
  }

  static _getMinutesUntilTokenExpiration() {
    var decodedJWT = jwtDecode(AuthManager.accessToken);
    var exp = decodedJWT.exp * 1000;
    var expirationTime = moment(exp);
    var today = moment();
    let absoluteDifference = Math.abs(expirationTime.diff(today, 'minutes'));
    return absoluteDifference;
  }

  static async validateTokens(onSuccess, onError) {
    let remainingMinutes = AuthManager._getMinutesUntilTokenExpiration();
    if (remainingMinutes > 1) {
      onSuccess();
      return;
    }

    return AuthManager.refreshTokens(() => {
      return onSuccess();
    }).catch((error) => {
      onError();
      // Token has expired.
      window.location.href = '/login';
    });
  }

  static refreshTokens() {
    return AsyncStorage.getCookie(KEY_USER_ID)
      .then((userId) => {
        if (!userId) {
          throw new Error({ message: 'No Refresh Token Found' });
        }
        // try and refresh the token if we find it, if this fails
        // our token has expired and we will need user to re login
        // manually
        const data = {
          user_id: userId,
        };

        return FetchHelper.post(
          window.Api.User.RefreshToken,
          data,
          false,
          false,
        );
      })
      .then((tokenResponse) => {
        return AuthManager._updateTokens(tokenResponse);
      });
  }

  static silentLogin() {
    return AuthManager.refreshTokens()
      .then(() => {
        return FetchHelper.get(window.Api.User.Info);
      })
      .then((responseJson) => {
        AuthManager._setUser(responseJson);
        return AuthManager.currentUser;
      })
      .catch((error) => {
        AuthManager.userId = null;
        AuthManager.accessToken = null;
        AuthManager.refreshToken = null;
        throw error;
      });
  }

  static async logout() {
    let data = { refresh: AuthManager.refreshToken };
    return FetchHelper.post(window.Api.User.Logout, data).then(() => {
      return AuthManager.removeCredentials();
    });
  }

  static requestResetPassword(email) {
    return FetchHelper.post(
      window.Api.User.RequestResetPassword,
      {
        email,
      },
      false,
      false,
    );
  }

  static resetPassword(email, password, code) {
    let data = {
      email,
      password,
      verification_code: code,
    };
    return FetchHelper.post(window.Api.User.ResetPassword, data, false, true);
  }

  static removeCredentials() {
    AuthManager.accessToken = null;
    AuthManager.refreshToken = null;
    AuthManager.isLoggedIn = false;
    AuthManager.currentUser = null;
    AsyncStorage.removeCookie(KEY_USER_ID);
    AsyncStorage.removeCookie(KEY_ACCESS_TOKEN);
    return AsyncStorage.removeCookie(KEY_REFRESH_TOKEN);
  }

  static getError(error) {
    var errorMessage = 'An unexpected error occured';
    if (error.email) {
      errorMessage = error.email[0];
    } else if (error.message) {
      errorMessage = error.message;
    } else if (error.non_field_errors) {
      errorMessage = error.non_field_errors[0];
    } else if (error.detail) {
      errorMessage = error.detail;
    }
    return { error: errorMessage, message: errorMessage };
  }

  static _updateTokens(responseJson) {
    AuthManager.accessToken = responseJson.tokens.access;
    AuthManager.refreshToken = responseJson.tokens.refresh;
    AuthManager.userId = responseJson.user.id;
    AsyncStorage.setCookie(KEY_USER_ID, AuthManager.userId, 28);
    AsyncStorage.setCookie(KEY_ACCESS_TOKEN, AuthManager.accessToken, 28);
    AsyncStorage.setCookie(KEY_REFRESH_TOKEN, AuthManager.refreshToken, 28);
  }

  static _setUser(responseJson) {
    AuthManager.isLoggedIn = true;
    AuthManager.currentUser = this._getCurrentUser(responseJson);
  }

  static _getCurrentUser(responseJson) {
    if (responseJson.user) {
      return responseJson.user;
    }
    return null;
  }

  static getHeaders(contentType = 'application/json') {
    var headers = {};
    if (contentType === 'application/json') {
      headers = {
        'Content-Type': contentType,
        'X-Requested-With': 'XMLHttpRequest',
        'Access-Control-Allow-Origin': '*',
      };
    }
    if (AuthManager.accessToken) {
      headers['Authorization'] = 'Bearer ' + AuthManager.accessToken;
    }

    return new Headers(headers);
  }
}
