import { CCol, CRow } from '@coreui/react';
import React, { useEffect, useState } from 'react';
import { useParams, withRouter } from 'react-router-dom';
import Backend from '../../utils/Backend';
import renderHTML from 'react-render-html';

function Page(props) {
  const { pageId } = useParams();
  const [page, setPage] = useState(null);

  const getPage = async () => {
    try {
      const response = await Backend.getPage(pageId);
      setPage(response.data);
    } catch (e) {}
  };

  useEffect(() => {
    getPage();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {page && (
        <>
          <CRow className={'mt-2 mb-2 text-center'}>
            <CCol>
              <h5>{page.title}</h5>
            </CCol>
          </CRow>
          <CRow className={'mt-2 mb-2'}>
            <CCol className={'pl-4 pr-4 text-left'}>
              {page.content !== '' ? renderHTML(page.content) : ''}
            </CCol>
          </CRow>
        </>
      )}
    </>
  );
}

export default withRouter(Page);
