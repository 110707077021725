import {
  CAlert,
  CButton,
  CCard,
  CCardBody,
  CCol,
  CForm,
  CFormGroup,
  CInput,
  CLabel,
  CRow,
  CSpinner,
} from '@coreui/react';
import React, { useEffect, useState } from 'react';
import Backend from '../../utils/Backend';
import Notify from '../../utils/Notify';

export default function Settings() {
  const settingsModel = {
    winner_predict_point: 0,
    score_predict_point: 0,
    prediction_open_before: 0,
    round_cost_in_rupees: 0,
    round_cost_in_coins: 0,
  };
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [settings, setSettings] = useState(settingsModel);

  const initSettings = async () => {
    const response = await Backend.getSettings();

    if (response.data && response.data.length) {
      response.data.forEach((item) => {
        settingsModel[item.name] = item.value;
      });
      setSettings({ ...settingsModel });
    }
  };

  const handleFormChange = (evt) => {
    setError('');
    let name = evt.target.name;
    let value = evt.target.value;
    setSettings({ ...settings, [name]: value });
  };

  const validateData = () => {
    if (isNaN(settings.winner_predict_point)) {
      setError('Enter valid value of Winner Point');
      return false;
    } else if (isNaN(settings.score_predict_point)) {
      setError('Enter valid value of Score Point');
      return false;
    } else if (isNaN(settings.prediction_open_before)) {
      setError('Enter valid value of Prediction open before');
      return false;
    } else if (!settings.rupees_coins_values) {
      setError('Enter rupees coin conversion values');
      return false;
    } else if (isNaN(settings.round_cost_in_coins)) {
      setError('Enter valid value of Round cost in coins');
      return false;
    } else if (isNaN(settings.round_prize_pool_percent)) {
      setError('Enter valid value of Round Prize pool percent');
      return false;
    }

    return true;
  };

  const handleSubmit = async () => {
    setLoading(true);

    if (!validateData()) {
      setLoading(false);
      return;
    }

    try {
      Backend.updateSettings(settings);
      Notify.success('Settings updated successfully.');
      setLoading(false);
    } catch (error) {
      console.log(error);
      setError(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    initSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <CRow>
        <CCol xs={'12'}>
          <h4>Settings</h4>
        </CCol>
      </CRow>

      <CRow className={'mt-3'}>
        <CCol xs='12' lg='12'>
          <CCard>
            <CCardBody>
              {error && (
                <CAlert color='danger' className='w-100'>
                  {error}
                </CAlert>
              )}

              <CForm
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                <CFormGroup>
                  <CLabel>Winner Predict Point</CLabel>
                  <CInput
                    value={settings.winner_predict_point}
                    type='number'
                    name='winner_predict_point'
                    onChange={(e) => handleFormChange(e)}
                  />
                </CFormGroup>
                <CFormGroup>
                  <CLabel>Score Predict Point</CLabel>
                  <CInput
                    value={settings.score_predict_point}
                    type='number'
                    name='score_predict_point'
                    onChange={(e) => handleFormChange(e)}
                  />
                </CFormGroup>
                <CFormGroup>
                  <CLabel>Prediction open before Match start (in hrs)</CLabel>
                  <CInput
                    value={settings.prediction_open_before}
                    type='number'
                    name='prediction_open_before'
                    onChange={(e) => handleFormChange(e)}
                  />
                </CFormGroup>
                <CFormGroup>
                  <CLabel>
                    Rupees:Coins values/conversion separated by Comma (eg:
                    10:200, 20:500)
                  </CLabel>
                  <CInput
                    value={settings.rupees_coins_values}
                    type='text'
                    name='rupees_coins_values'
                    onChange={(e) => handleFormChange(e)}
                  />
                </CFormGroup>
                <CFormGroup>
                  <CLabel>Round cost in Coins</CLabel>
                  <CInput
                    value={settings.round_cost_in_coins}
                    type='number'
                    name='round_cost_in_coins'
                    onChange={(e) => handleFormChange(e)}
                  />
                </CFormGroup>
                <CFormGroup>
                  <CLabel>Round Prize Pool percent</CLabel>
                  <CInput
                    value={settings.round_prize_pool_percent}
                    type='number'
                    min={0}
                    name='round_prize_pool_percent'
                    onChange={(e) => handleFormChange(e)}
                  />
                </CFormGroup>
                <CFormGroup className={'text-right mt-2'}>
                  <CButton
                    color='dark'
                    className='btn-sm'
                    disabled={loading}
                    onClick={(e) => {
                      e.preventDefault();
                      handleSubmit();
                    }}
                  >
                    {loading ? (
                      <>
                        <CSpinner
                          component='span'
                          size='sm'
                          aria-hidden='true'
                        />{' '}
                        Saving...
                      </>
                    ) : (
                      'Update Settings'
                    )}
                  </CButton>
                </CFormGroup>
              </CForm>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
}
