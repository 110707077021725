import { CIcon } from '@coreui/icons-react';
import {
  CAlert,
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CFormGroup,
  CInput,
  CLabel,
  CRow,
} from '@coreui/react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useParams, withRouter } from 'react-router-dom';
import { defaultConfirmOptions } from '../../constants/Options';
import Backend from '../../utils/Backend';
import Notify from '../../utils/Notify';
import Loading from '../components/Loading';
import ConfirmationModal from './modals/ConfirmationModal';
import MatchesModal from './modals/MatchesModal';

function League(props) {
  const { leagueId } = useParams();
  const [league, setLeague] = useState(null);
  const [matches, setMatches] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showAddMatches, setShowAddMatches] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [confirmOptions, setConfirmOptions] = useState(defaultConfirmOptions);

  const deleteMatch = async (match) => {
    if (match === null) {
      setShowConfirmModal(false);
      Notify.error('Please select Match');
      return;
    }

    try {
      await Backend.deleteMatch(match.id);
      const updatedMatches = matches.filter(
        (item) => item.id !== match.id && item,
      );
      setMatches([...updatedMatches]);
      setShowConfirmModal(false);
      Notify.success('Match deleted successfully.');
    } catch (error) {
      setShowConfirmModal(false);
      Notify.error(error.message);
    }
  };

  const initLeague = async () => {
    setLoading(true);

    try {
      const response = await Backend.getLeague(leagueId);
      if (response) {
        setLeague(response.data);
        const matchResponse = await Backend.getLeagueMatches(leagueId);
        setMatches([...matchResponse.data]);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      Notify.error(error.message);
      setLoading(false);
    }
  };

  const changeScore = (e, matchId) => {
    // if (!parseInt(e.target.value)) {
    //   return
    // }

    let updateMatches = matches.map((item) => {
      if (item.id === matchId) {
        return { ...item, [e.target.name]: parseInt(e.target.value) };
      }

      return item;
    });

    setMatches([...updateMatches]);
  };

  const saveScore = async (matchId) => {
    let match = matches.find((item) => item.id === matchId && item);

    if (match !== undefined) {
      if (typeof match.score1 != 'number' || typeof match.score2 != 'number') {
        Notify.error('Enter valid value of Scores');
        return;
      }

      try {
        let updatedMatch = {
          ...match,
          result_announce_at: moment().format('YYYY-MM-DD H:mm:ss'),
        };
        await Backend.updateMatch(match.id, updatedMatch);
        const updatedMatches = matches.map((item) => {
          if (item.id === updatedMatch.id) {
            return updatedMatch;
          }
          return item;
        });
        setMatches([...updatedMatches]);
        Notify.success('Score updated successfully.');
      } catch (error) {
        Notify.error(error.message);
      }
    }
  };

  useEffect(() => {
    initLeague();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <CRow>
        <CCol>
          <h4>{league ? league.name : ''}</h4>
        </CCol>
        <CCol className='text-right'>
          <CButton
            color='dark'
            className='btn-sm'
            onClick={() => setShowAddMatches(!showAddMatches)}
          >
            <CIcon name='cil-plus' /> Add Matches
          </CButton>
        </CCol>
      </CRow>

      <CRow className={'mt-3'}>
        <CCol xs='12' lg='12'>
          <CCard>
            <CCardHeader>
              <CRow>
                <CCol>
                  <h6>Matches</h6>
                </CCol>
              </CRow>
              <CRow></CRow>
            </CCardHeader>
            <CCardBody>
              {loading ? (
                <Loading />
              ) : (
                matches.map((item) => {
                  return (
                    <CRow key={item.id}>
                      <CCol>
                        <CAlert
                          color={
                            item.result_announce_at != null ? 'success' : 'info'
                          }
                          className={''}
                        >
                          <CRow>
                            <CCol className={'col-sm-4'}>
                              <CFormGroup>
                                <CLabel>Kickoff</CLabel>
                                <br />
                                <CLabel>
                                  <strong>
                                    {moment(item.start_at).format(
                                      'DD/MM/YYYY hh:mm A',
                                    )}
                                  </strong>
                                </CLabel>
                              </CFormGroup>
                            </CCol>
                            <CCol className={'col-sm-3'}>
                              <CFormGroup>
                                <CLabel>Home Team</CLabel>
                                <br />
                                <CLabel>
                                  <strong>{item.team1}</strong>
                                </CLabel>
                                <br />
                                {item.result_announce_at == null && (
                                  <CInput
                                    type={'number'}
                                    name={'score1'}
                                    min={0}
                                    onChange={(e) => {
                                      changeScore(e, item.id);
                                    }}
                                  />
                                )}
                              </CFormGroup>
                            </CCol>
                            {item.result_announce_at != null && (
                              <CCol className={'col-sm-2'}>
                                <CFormGroup>
                                  <CLabel>&nbsp;</CLabel>
                                  <br />
                                  <CLabel>
                                    <strong>
                                      {item.score1} - {item.score2}
                                    </strong>
                                  </CLabel>
                                  <br />
                                </CFormGroup>
                              </CCol>
                            )}
                            <CCol className={'col-sm-3'}>
                              <CFormGroup>
                                <CLabel>Away Team</CLabel>
                                <br />
                                <CLabel>
                                  <strong>{item.team2}</strong>
                                </CLabel>
                                <br />
                                {item.result_announce_at == null && (
                                  <CInput
                                    type={'number'}
                                    name={'score2'}
                                    min={0}
                                    onChange={(e) => {
                                      changeScore(e, item.id);
                                    }}
                                  />
                                )}
                              </CFormGroup>
                            </CCol>
                            {item.result_announce_at == null && (
                              <CCol className={'col-sm-2 text-right'}>
                                <CFormGroup>
                                  <CButton
                                    color='danger'
                                    className='btn-sm mb-1'
                                    onClick={(e) => {
                                      setConfirmOptions({
                                        ...confirmOptions,
                                        content: `Are you sure you want to delete this Match?`,
                                        onConfirm: () => deleteMatch(item),
                                      });
                                      setShowConfirmModal(true);
                                    }}
                                  >
                                    <CIcon name={'cil-trash'} />
                                  </CButton>{' '}
                                  <br />
                                  <CButton
                                    color='dark'
                                    className='btn-sm mt-4'
                                    onClick={(e) => {
                                      saveScore(item.id);
                                    }}
                                  >
                                    Add Score
                                  </CButton>
                                </CFormGroup>
                              </CCol>
                            )}
                          </CRow>
                        </CAlert>
                      </CCol>
                    </CRow>
                  );
                })
              )}
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>

      {showAddMatches && (
        <MatchesModal
          show={showAddMatches}
          league={league}
          onClose={() => {
            setShowAddMatches(false);
            initLeague();
          }}
        />
      )}

      {showConfirmModal && (
        <ConfirmationModal
          show={showConfirmModal}
          title={confirmOptions.title}
          content={() => (
            <>
              <CAlert color={confirmOptions.type} className='w-100'>
                {confirmOptions.content}
              </CAlert>
            </>
          )}
          onClose={() => {
            setShowConfirmModal(false);
          }}
          confirmText={confirmOptions.confirmText}
          onConfirm={confirmOptions.onConfirm}
        />
      )}
    </>
  );
}

export default withRouter(League);
