import {
  CAlert,
  CButton,
  CCol,
  CContainer,
  CForm,
  CFormGroup,
  CInput,
  CLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CSpinner,
} from '@coreui/react';
import React, { useEffect, useState } from 'react';
import Backend from '../../../utils/Backend';
import Notify from '../../../utils/Notify';

export default function RewardModal(props) {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [reward, setReward] = useState({
    id: '',
    name: '',
    percentage: 0,
    rank: '',
    positions: '',
  });

  function handleFormChange(evt) {
    setError('');
    let name = evt.target.name;
    let value = evt.target.value;
    setReward({ ...reward, [name]: value });
  }

  function validateData() {
    if (!reward.name) {
      setError('Enter Reward name');
      return false;
    } else if (!reward.percentage) {
      setError('Enter Reward Percentage');
      return false;
    } else if (!reward.rank) {
      setError('Enter Reward Rank');
      return false;
    }

    return true;
  }

  async function handleSubmit() {
    setLoading(true);

    if (!validateData()) {
      setLoading(false);
      return;
    }

    try {
      if (!props.reward) {
        const response = await Backend.addReward(reward);
        props.onAdded(response.data);
        Notify.success('Reward added successfully.');
      } else {
        const response = await Backend.updateReward(reward.id, reward);
        props.onUpdated(response.data);
        Notify.success('Reward updated successfully.');
      }
      props.onClose();
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  }

  useEffect(() => {
    if (props.reward) {
      setReward(props.reward);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <CModal show={props.show} centered={true} onClose={() => props.onClose()}>
        <CModalHeader closeButton>
          <CModalTitle>{props.reward ? 'Add' : 'Edit'} Reward</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CContainer fluid>
            <CRow>
              {error && (
                <CAlert color='danger' className='w-100'>
                  {error}
                </CAlert>
              )}
              <CCol sm='12'>
                <CForm
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                  }}
                >
                  <CFormGroup>
                    <CLabel>Reward Name</CLabel>
                    <CInput
                      value={reward.name}
                      type='text'
                      name='name'
                      placeholder='Enter Reward Name'
                      onChange={(e) => handleFormChange(e)}
                    />
                  </CFormGroup>
                  <CFormGroup>
                    <CLabel>Reward Prize Percentage (from prize pool)</CLabel>
                    <CInput
                      value={reward.percentage}
                      type='number'
                      name='percentage'
                      min={0}
                      placeholder='Enter Reward Percentage'
                      onChange={(e) => handleFormChange(e)}
                    />
                  </CFormGroup>
                  <CFormGroup>
                    <CLabel>
                      Reward Ranking (Eg. 1 for Diamond, 2 for Platinum...)
                    </CLabel>
                    <CInput
                      value={reward.rank}
                      type='number'
                      name='rank'
                      min={1}
                      placeholder='Enter Reward Rank'
                      onChange={(e) => handleFormChange(e)}
                    />
                  </CFormGroup>
                  <CFormGroup>
                    <CLabel>
                      Reward Positions (Eg. 1 for Diamond, 2 for Platinum...,
                      4-10 for Silver etc..)
                    </CLabel>
                    <CInput
                      value={reward.positions}
                      type='text'
                      name='positions'
                      min={1}
                      placeholder='Enter Reward Positions'
                      onChange={(e) => handleFormChange(e)}
                    />
                  </CFormGroup>
                </CForm>
              </CCol>
            </CRow>
          </CContainer>
        </CModalBody>
        <CModalFooter>
          <CButton
            variant='outline'
            color='danger'
            className='btn-sm pl-4 pr-4'
            disabled={loading}
            onClick={() => props.onClose()}
          >
            Cancel
          </CButton>
          <CButton
            color='dark'
            className='btn-sm pl-4 pr-4'
            disabled={loading}
            onClick={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            {loading ? (
              <>
                <CSpinner component='span' size='sm' aria-hidden='true' />{' '}
                Saving...
              </>
            ) : props.reward ? (
              'Update Reward'
            ) : (
              'Add Reward'
            )}
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  );
}
