import { CIcon } from '@coreui/icons-react';
import {
  CAlert,
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CPagination,
  CRow,
  CSelect,
  CTooltip,
} from '@coreui/react';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { defaultConfirmOptions } from '../../constants/Options';
import Backend from '../../utils/Backend';
import Notify from '../../utils/Notify';
import Loading from '../components/Loading';
import ConfirmationModal from './modals/ConfirmationModal';
import GameWeekModal from './modals/GameWeekModal';
import RoundModal from './modals/RoundModal';

function Rounds(props) {
  const leagueRef = useRef();
  const [loading] = useState(false);
  const [leagues, setLeagues] = useState([]);
  const [rounds, setRounds] = useState([]);
  const [selectedRound, setSelectedRound] = useState();
  const [showAddRound, setShowAddRound] = useState(false);
  const [showGameWeeks, setShowGameWeeks] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage] = useState(1);
  const [confirmOptions, setConfirmOptions] = useState(defaultConfirmOptions);

  const deleteRound = async (round) => {
    if (round == null) {
      setShowConfirmModal(false);
      Notify.error('Please select Round');
      return;
    }

    try {
      await Backend.deleteRound(round.id);
      const updatedRounds = rounds.filter(
        (item) => item.id !== round.id && item,
      );
      setRounds([...updatedRounds]);
      setShowConfirmModal(false);
      setSelectedRound(null);
      Notify.success('Round deleted successfully.');
    } catch (error) {
      setShowConfirmModal(false);
      Notify.error(error.message);
    }
  };

  const handleLeagueChange = (e) => {
    setRounds([]);
    leagues.forEach((league) => {
      if (Number(e.target.value) === league.id) {
        setRounds([...league.rounds]);
      }
    });
  };

  const getLeagues = async () => {
    const param = '?has_active_rounds=1';
    try {
      const response = await Backend.getAllLeagues(param);
      if (response) {
        setLeagues([...response]);
        if (response.length) {
          setRounds([...response[0].rounds]);
          leagueRef.current.value = response[0].id;
          // getRounds()
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    getLeagues();
  }, [currentPage]);

  return (
    <>
      <CRow>
        <CCol>
          <h4>Rounds</h4>
        </CCol>
        <CCol className='text-right'>
          <CButton
            color='dark'
            className='btn-sm'
            onClick={() => setShowAddRound(!showAddRound)}
          >
            <CIcon name='cil-plus' /> Add Round
          </CButton>
        </CCol>
      </CRow>

      <CRow className={'mt-3'}>
        <CCol xs='12' lg='12'>
          <CCard>
            <CCardHeader>
              <CRow>
                <CCol>
                  <h6>Rounds</h6>
                </CCol>
              </CRow>
              <CRow>
                <CCol className={'col-8 pr-0'}>
                  <CSelect
                    innerRef={leagueRef}
                    onChange={(e) => {
                      handleLeagueChange(e);
                    }}
                  >
                    {leagues.map((league) => {
                      return (
                        <option key={league.id} value={league.id}>
                          {league.name}
                        </option>
                      );
                    })}
                  </CSelect>
                </CCol>
              </CRow>
            </CCardHeader>
            <CCardBody>
              {loading ? (
                <Loading />
              ) : (
                <>
                  <CDataTable
                    items={rounds}
                    striped
                    outlined={true}
                    fields={[
                      {
                        key: 'name',
                        label: 'Name',
                      },
                      {
                        key: 'start',
                        label: 'Start Date',
                      },
                      {
                        key: 'end',
                        label: 'End Date',
                      },
                      {
                        key: 'active',
                        label: 'Active',
                      },
                      {
                        key: 'actions',
                        label: 'Actions',
                      },
                    ]}
                    scopedSlots={{
                      start: (item) => {
                        return (
                          <td>{moment(item.start).format('DD/MM/YYYY')}</td>
                        );
                      },
                      end: (item) => {
                        return <td>{moment(item.end).format('DD/MM/YYYY')}</td>;
                      },
                      active: (item) => {
                        return <td>{item.active ? 'Yes' : 'No'}</td>;
                      },
                      actions: (item) => {
                        return (
                          <td>
                            <CButtonGroup className={'btn-sm'}>
                              <CTooltip
                                content='View Round Details'
                                placement='bottom'
                              >
                                <CButton
                                  color='success'
                                  variant='outline'
                                  className={'btn-sm'}
                                  onClick={() => {
                                    props.history.push(
                                      `/admin/rounds/${item.id}`,
                                    );
                                  }}
                                >
                                  <CIcon name='cil-grid'></CIcon>
                                </CButton>
                              </CTooltip>
                              <CTooltip
                                content='Add/Edit Game Week'
                                placement='bottom'
                              >
                                <CButton
                                  color='success'
                                  variant='outline'
                                  className={'btn-sm'}
                                  onClick={() => {
                                    setSelectedRound(item);
                                    setShowGameWeeks(true);
                                  }}
                                >
                                  <CIcon name='cil-plus'></CIcon>
                                </CButton>
                              </CTooltip>
                              <CTooltip content='Edit Round' placement='bottom'>
                                <CButton
                                  color='dark'
                                  variant='outline'
                                  className={'btn-sm'}
                                  onClick={() => {
                                    setSelectedRound(item);
                                    setShowAddRound(true);
                                  }}
                                >
                                  <CIcon name='cil-pen-alt'></CIcon>
                                </CButton>
                              </CTooltip>
                              <CTooltip
                                content='Delete Round'
                                placement='bottom'
                              >
                                <CButton
                                  color='danger'
                                  variant='outline'
                                  className={'btn-sm'}
                                  onClick={() => {
                                    setConfirmOptions({
                                      ...confirmOptions,
                                      content: `Are you sure you want to delete ${item.name}? All match weeks of this Round will be deleted.`,
                                      onConfirm: () => deleteRound(item),
                                    });
                                    setShowConfirmModal(true);
                                  }}
                                >
                                  <CIcon name='cil-trash'></CIcon>
                                </CButton>
                              </CTooltip>
                            </CButtonGroup>
                          </td>
                        );
                      },
                    }}
                  />
                  <CPagination
                    className={'mt-2'}
                    activePage={currentPage}
                    pages={lastPage}
                    onActivePageChange={(i) => setCurrentPage(i)}
                  ></CPagination>
                </>
              )}
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>

      {showAddRound && (
        <RoundModal
          show={showAddRound}
          round={selectedRound}
          onAdded={(round) => {
            setSelectedRound(null);
            rounds.unshift(round);
            setRounds([...rounds]);
          }}
          onUpdated={(round) => {
            setSelectedRound(null);
            const updatedRounds = rounds.map((item) => {
              if (item.id === round.id) {
                return round;
              } else {
                return item;
              }
            });
            setRounds([...updatedRounds]);
          }}
          onClose={() => {
            setSelectedRound(null);
            setShowAddRound(false);
          }}
        />
      )}

      {showGameWeeks && (
        <GameWeekModal
          show={showGameWeeks}
          round={selectedRound}
          onClose={() => {
            setShowGameWeeks(false);
            setSelectedRound(null);
            // getRounds()
          }}
        />
      )}

      {showConfirmModal && (
        <ConfirmationModal
          show={showConfirmModal}
          title={confirmOptions.title}
          content={() => (
            <>
              <CAlert color={confirmOptions.type} className='w-100'>
                {confirmOptions.content}
              </CAlert>
            </>
          )}
          onClose={() => {
            setShowConfirmModal(false);
          }}
          confirmText={confirmOptions.confirmText}
          onConfirm={confirmOptions.onConfirm}
        />
      )}
    </>
  );
}

export default withRouter(Rounds);
