import { CIcon } from "@coreui/icons-react";
import {
  CAlert,
  CButton,
  CCol,
  CContainer,
  CFormGroup,
  CInput,
  CLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CSelect,
  CSpinner,
  CTooltip,
} from "@coreui/react";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";
import CustomDatePicker from "../../components/CustomDatePicker";

export default function MatchesModal(props) {
  const league = props.league;
  const matchesContainerRef = useRef(null);
  const roundRef = useRef(null);
  const gameWeekRef = useRef(null);
  const [error, setError] = useState("");
  const [loading] = useState(false);
  const [rounds, setRounds] = useState([]);
  const [gameWeeks, setGameWeeks] = useState([]);
  const [matches, setMatches] = useState([]);
  const [winnerPoint, setWinnerPoint] = useState(1);
  const [scorePoint, setScorePoint] = useState(5);
  const [predictionOpenHrs, setPredictionOpenHrs] = useState(48);
  const matchModel = {
    league_id: league.id,
    game_week_id: "",
    team1: "",
    team2: "",
    winner_predict_point: winnerPoint,
    score_predict_point: scorePoint,
    start_at: "",
    prediction_start_at: "",
    result_announce_at: null,
  };

  const addMatch = () => {
    setError("");
    let data = matches;
    data.push(matchModel);
    setMatches([...data]);
    matchesContainerRef.current.scrollIntoView({
      block: "end",
      behaviour: "smooth",
    });
  };

  const removematch = (index) => {
    setError("");
    setMatches([...matches.filter((item, i) => i !== index && item)]);
  };

  const handleFormChange = (evt, index) => {
    setError("");
    let name = evt.target.name;
    let value = evt.target.value;
    setMatches([
      ...matches.map((item, i) => {
        if (i === index) {
          return { ...item, [name]: value };
        }
        return item;
      }),
    ]);
  };

  const initSettings = async () => {
    try {
      const response1 = await Backend.getSetting("winner_predict_point");
      const response2 = await Backend.getSetting("score_predict_point");
      const response3 = await Backend.getSetting("prediction_open_before");
      setWinnerPoint(response1.data.value);
      setScorePoint(response2.data.value);
      setPredictionOpenHrs(response3.data.value ? response3.data.value : 48);
    } catch (error) {
      console.log(error);
    }
  };

  const initRounds = async (params) => {
    try {
      const response = await Backend.getRounds(params);
      if (response.data) {
        setRounds([...response.data.data]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const saveMatches = async () => {
    setError("");
    try {
      let successIndexes = [];
      await Promise.all(
        matches.map(async (match, index) => {
          try {
            match.game_week_id = gameWeekRef.current.value
              ? gameWeekRef.current.value
              : "";
            await Backend.addMatch(match);
            successIndexes.push(index);
            Notify.success("Match added successfully.");
          } catch (error) {
            setError(error.message);
            //Notify.error(error.message);
          }
        })
      );
      setMatches([
        ...matches.filter(
          (match, index) => !successIndexes.includes(index) && match
        ),
      ]);
    } catch (error) {
      console.log(error);
      Notify.error(error.message);
    }
  };

  useEffect(() => {
    matches.push(matchModel);
    initSettings();
    initRounds(`?league_id=${props.league.id}`);

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <CModal
        show={props.show}
        centered={true}
        size={"xl"}
        className={"fw-modal"}
        onClose={() => props.onClose()}
      >
        <CModalHeader closeButton>
          <CModalTitle>
            Add <strong>{league.name}</strong> Matches{" "}
          </CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CContainer fluid>
            <CRow>
              {error && (
                <CAlert color={"danger"} className={"w-100"}>
                  {error}
                </CAlert>
              )}
            </CRow>
            <CRow>
              <CCol className>
                <CFormGroup>
                  <CLabel>
                    Select Round <small>(Optional)</small>
                  </CLabel>
                  <CSelect
                    innerRef={roundRef}
                    onChange={(e) => {
                      if (e.target.value) {
                        rounds.forEach((round) => {
                          if (Number(e.target.value) === round.id) {
                            setGameWeeks([...round.game_weeks]);
                          }
                        });
                      } else {
                        setGameWeeks([]);
                      }
                    }}
                  >
                    <option value="">---------</option>
                    {rounds.map((round) => {
                      return (
                        <option key={round.id} value={round.id}>
                          {round.name}
                        </option>
                      );
                    })}
                  </CSelect>
                </CFormGroup>
              </CCol>
              <CCol>
                <CFormGroup>
                  <CLabel>
                    Select Game Week <small>(Optional)</small>
                  </CLabel>
                  <CSelect
                    innerRef={gameWeekRef}
                    onChange={(e) => {
                      // setGameWeeks([])
                    }}
                  >
                    <option value="">----------</option>
                    {gameWeeks.map((gameWeek) => {
                      return (
                        <option key={gameWeek.id} value={gameWeek.id}>
                          {gameWeek.name}
                        </option>
                      );
                    })}
                  </CSelect>
                </CFormGroup>
              </CCol>
            </CRow>
            <CRow className={"mt-1 mb-2"}>
              <CCol className={"text-left"}>
                <CAlert color={"info"}>All fields are required*</CAlert>
              </CCol>
              <CCol className={"text-right"}>
                <CTooltip content={"Add more Match"}>
                  <CButton
                    color="success"
                    className="btn-sm pl-4 pr-4"
                    onClick={(e) => {
                      addMatch();
                    }}
                  >
                    <CIcon name={"cil-plus"} />
                  </CButton>
                </CTooltip>
              </CCol>
            </CRow>
            <CRow
              innerRef={matchesContainerRef}
              className={"add-matches-container"}
            >
              {matches.map((item, index) => {
                return (
                  <CAlert
                    key={index}
                    color={
                      item.result_announce_at !== null ? "success" : "dark"
                    }
                    className={"mb-3 w-100"}
                  >
                    <CRow>
                      <CCol className={"col-sm-12"}>
                        {item.result_announce_at !== null ? (
                          <></>
                        ) : (
                          <>
                            <CRow>
                              <CCol className={"col-sm-4"}>
                                <CFormGroup>
                                  <CLabel>Home Team</CLabel>
                                  <CInput
                                    value={item.team1}
                                    name={"team1"}
                                    onChange={(e) => {
                                      handleFormChange(e, index);
                                    }}
                                  />
                                </CFormGroup>
                              </CCol>
                              <CCol className={"col-sm-4"}>
                                <CFormGroup>
                                  <CLabel>Away Team</CLabel>
                                  <CInput
                                    value={item.team2}
                                    name={"team2"}
                                    onChange={(e) => {
                                      handleFormChange(e, index);
                                    }}
                                  />
                                </CFormGroup>
                              </CCol>
                              <CCol className={""}>
                                <CFormGroup>
                                  <CLabel>Score Predict Point</CLabel>
                                  <CInput
                                    value={item.score_predict_point}
                                    name={"score_predict_point"}
                                    onChange={(e) => {
                                      handleFormChange(e, index);
                                    }}
                                  />
                                </CFormGroup>
                              </CCol>
                              <CCol className={""}>
                                <CFormGroup>
                                  <CLabel>Winner Predict Point</CLabel>
                                  <CInput
                                    value={item.winner_predict_point}
                                    name={"winner_predict_point"}
                                    onChange={(e) => {
                                      handleFormChange(e, index);
                                    }}
                                  />
                                </CFormGroup>
                              </CCol>
                            </CRow>
                            <CRow>
                              <CCol className={"col-sm-4"}>
                                <CFormGroup>
                                  <CustomDatePicker
                                    time={true}
                                    label={"Match Start Date & Time"}
                                    value={
                                      item.start_at
                                        ? moment(item.start_at).format(
                                            "DD/MM/YYYY hh:mm A"
                                          )
                                        : ""
                                    }
                                    onApply={(date) => {
                                      setError("");
                                      console.log(
                                        moment(date).format("YYYY-MM-DD hh:mm")
                                      );
                                      setMatches([
                                        ...matches.map((match, i) => {
                                          if (i === index) {
                                            return {
                                              ...match,
                                              start_at:
                                                moment(date).format(
                                                  "YYYY-MM-DD HH:mm"
                                                ),
                                              prediction_start_at: moment(date)
                                                .subtract(
                                                  predictionOpenHrs,
                                                  "h"
                                                )
                                                .format("YYYY-MM-DD HH:mm"),
                                            };
                                          }
                                          return match;
                                        }),
                                      ]);
                                    }}
                                  />
                                </CFormGroup>
                              </CCol>
                              <CCol className={"col-sm-4"}>
                                <CFormGroup>
                                  <CLabel>Prediction open Date & Time</CLabel>
                                  <CInput
                                    disabled={true}
                                    value={
                                      item.start_at
                                        ? moment(item.start_at)
                                            .subtract(predictionOpenHrs, "h")
                                            .format("DD/MM/YYYY hh:mm A")
                                        : "--/--/---- --:-- -"
                                    }
                                  />
                                </CFormGroup>
                              </CCol>
                              <CCol className={"text-right"}>
                                <CFormGroup>
                                  <CLabel className={"w-100"}>&nbsp;</CLabel>
                                  <CButton
                                    color="danger"
                                    className="btn-sm pl-4 pr-4"
                                    onClick={(e) => {
                                      removematch(index);
                                    }}
                                  >
                                    <CIcon name={"cil-trash"} />
                                  </CButton>
                                </CFormGroup>
                              </CCol>
                            </CRow>
                          </>
                        )}
                      </CCol>
                    </CRow>
                  </CAlert>
                );
              })}
            </CRow>
          </CContainer>
        </CModalBody>
        <CModalFooter>
          <CButton
            color="dark"
            className="btn-sm pl-4 pr-4"
            disabled={loading || matches.length === 0}
            onClick={(e) => {
              e.preventDefault();
              saveMatches();
            }}
          >
            {loading ? (
              <>
                <CSpinner component="span" size="sm" aria-hidden="true" />{" "}
                Saving...
              </>
            ) : (
              "Add Matches"
            )}
          </CButton>
          <CButton
            variant="outline"
            color="danger"
            className="btn-sm pl-4 pr-4 ml-2"
            disabled={loading}
            onClick={() => props.onClose()}
          >
            Close
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  );
}
