import CIcon from '@coreui/icons-react'
import {
  CHeader, CHeaderBrand,
  CHeaderNav, CToggler
} from '@coreui/react'
import React from 'react'
import { useAuth } from '../../contexts/AuthContext'
import AuthManager from '../../utils/AuthManager'


function Header() {
  const { sidebarShow, setToggleSidebar, setCurrentUser } = useAuth()

  function toggleSidebar() {
    const val = [true, 'responsive'].includes(sidebarShow) ? false : 'responsive'
    setToggleSidebar(val)
  }

  function toggleSidebarMobile() {
    const val = [false, 'responsive'].includes(sidebarShow) ? true : 'responsive'
    setToggleSidebar(val)
  }

  async function handleLogout() {
    await AuthManager.logout()
    setCurrentUser(null)
    window.location = '/login'
  }

  return (
    <CHeader withSubheader>
      <CToggler
        inHeader
        className="ml-md-3 d-lg-none"
        onClick={() => toggleSidebarMobile()}
      />
      <CToggler
        inHeader
        className="ml-3 d-md-down-none"
        onClick={() => toggleSidebar()}
      />

      <CHeaderBrand className="mx-auto d-lg-none" to="/">
      </CHeaderBrand>

      <CHeaderNav className="d-md-down-none mr-auto">
      </CHeaderNav>

      <CHeaderNav className="px-3">
        {
          AuthManager.getCurrentUser() &&
          (
            <>
              <div className={'pr-3'}>Welcome, {AuthManager.getCurrentUser().name}</div>
              <CIcon name="cil-power-standby" height="20"
                style={{ cursor: 'pointer' }}
                onClick={() => handleLogout()} />
            </>
          )
        }

      </CHeaderNav>
    </CHeader>
  )
}

export default Header
