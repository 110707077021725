import { CContainer } from '@coreui/react'
import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Home from '../../pages/frontend/Home'
import Page from '../../pages/frontend/Page'
import Results from '../../pages/frontend/Results'
import Rewards from '../../pages/frontend/Rewards'
import Standings from '../../pages/frontend/Standings'
import Wallet from '../../pages/frontend/Wallet'
import Login from '../../pages/Login'

export default function Main() {
  return (
    <div className="c-main c-main-frontend">
      <CContainer >
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/standings" component={Standings} />
          <Route exact path="/results" component={Results} />
          <Route exact path="/wallet" component={Wallet} />
          <Route exact path="/rewards" component={Rewards} />
          <Route exact path="/page/:pageId" component={Page} />
          <Route exact path="/login" component={Login} />
        </Switch>
      </CContainer>
    </div>
  )
}
