const _nav = [
  {
    _tag: 'CSidebarNavItem',
    name: 'HOME',
    to: '/',
  },
  {
    _tag: 'CSidebarNavItem',
    name: 'STANDINGS',
    to: '/standings',
  }, {
    _tag: 'CSidebarNavItem',
    name: 'RESULTS',
    to: '/results',
  }, {
    _tag: 'CSidebarNavItem',
    name: 'WALLET',
    to: '/wallet',
  },{
    _tag: 'CSidebarNavItem',
    name: 'REWARDS',
    to: '/rewards',
  },
]

export default _nav
