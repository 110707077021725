import { CIcon } from '@coreui/icons-react';
import {
  CAlert,
  CButton,
  CCol,
  CFormGroup,
  CInput,
  CLabel,
  CRow,
} from '@coreui/react';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { defaultConfirmOptions } from '../../../../constants/Options';
import Backend from '../../../../utils/Backend';
import Notify from '../../../../utils/Notify';
import ConfirmationModal from '../../modals/ConfirmationModal';

function GameWeeks(props) {
  const [gameWeeks, setGameWeeks] = useState([]);
  const [matches, setMatches] = useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [confirmOptions, setConfirmOptions] = useState(defaultConfirmOptions);

  const removeMatch = async (match) => {
    if (match == null) {
      setShowConfirmModal(false);
      Notify.error('Please select Match');
      return;
    }

    try {
      await Backend.updateMatch(match.id, { game_week_id: null });
      const updatedMatches = matches.filter(
        (item) => item.id !== match.id && item,
      );
      setMatches([...updatedMatches]);
      setShowConfirmModal(false);
      Notify.success('Match removed from Game Week successfully.');
    } catch (error) {
      setShowConfirmModal(false);
      Notify.error(error.message);
    }
  };

  const changeScore = (e, matchId) => {
    let updateMatches = matches.map((item) => {
      if (item.id === matchId) {
        return { ...item, [e.target.name]: parseInt(e.target.value) };
      }

      return item;
    });

    setMatches([...updateMatches]);
  };

  const saveScore = async (matchId) => {
    let match = matches.find((item) => item.id === matchId && item);

    if (match !== undefined) {
      console.log(match);
      if (
        typeof match.score1 !== 'number' ||
        typeof match.score2 !== 'number'
      ) {
        Notify.error('Enter valid value of Scores');
        return;
      }

      try {
        let updatedMatch = {
          ...match,
          result_announce_at: moment().format('YYYY-MM-DD H:mm:ss'),
        };
        await Backend.updateMatch(match.id, updatedMatch);
        const updatedMatches = matches.map((item) => {
          if (item.id === updatedMatch.id) {
            return updatedMatch;
          }
          return item;
        });
        setMatches([...updatedMatches]);
        Notify.success('Score updated successfully.');
      } catch (error) {
        Notify.error(error.message);
      }
    }
  };

  useEffect(() => {
    if (props.gameWeeks) {
      console.log(props.gameWeeks);
      let allMatches = [];
      props.gameWeeks.forEach((gameWeek) => {
        gameWeek.matches.forEach((match) => allMatches.push(match));
      });
      setMatches([...allMatches]);
      setGameWeeks([...props.gameWeeks]);
    }
  }, [props.gameWeeks]);

  return (
    <>
      {gameWeeks.map((gameWeek) => {
        return (
          <CRow className={'mt-2'}>
            <CCol className={'col-sm-12'}>
              <strong>{gameWeek.name.toUpperCase()}</strong>
              <hr className={'mt-1'} />
            </CCol>
            {matches.map((item) => {
              if (item.game_week_id !== gameWeek.id) return undefined;

              return (
                <CCol className={'col-sm-12'}>
                  <CAlert
                    color={item.result_announce_at != null ? 'success' : 'info'}
                    className={''}
                  >
                    <CRow>
                      <CCol className={'col-sm-4'}>
                        <CFormGroup>
                          <CLabel>Kickoff</CLabel>
                          <br />
                          <CLabel>
                            <strong>
                              {moment(item.start_at).format(
                                'DD/MM/YYYY hh:mm A',
                              )}
                            </strong>
                          </CLabel>
                        </CFormGroup>
                      </CCol>
                      <CCol className={'col-sm-3'}>
                        <CFormGroup>
                          <CLabel>Home Team</CLabel>
                          <br />
                          <CLabel>
                            <strong>{item.team1}</strong>
                          </CLabel>
                          <br />
                          {item.result_announce_at == null && (
                            <CInput
                              type={'number'}
                              name={'score1'}
                              min={0}
                              onChange={(e) => {
                                changeScore(e, item.id);
                              }}
                            />
                          )}
                        </CFormGroup>
                      </CCol>
                      {item.result_announce_at != null && (
                        <CCol className={'col-sm-2'}>
                          <CFormGroup>
                            <CLabel>&nbsp;</CLabel>
                            <br />
                            <CLabel>
                              <strong>
                                {item.score1} - {item.score2}
                              </strong>
                            </CLabel>
                            <br />
                          </CFormGroup>
                        </CCol>
                      )}
                      <CCol className={'col-sm-3'}>
                        <CFormGroup>
                          <CLabel>Away Team</CLabel>
                          <br />
                          <CLabel>
                            <strong>{item.team2}</strong>
                          </CLabel>
                          <br />
                          {item.result_announce_at == null && (
                            <CInput
                              type={'number'}
                              name={'score2'}
                              min={0}
                              onChange={(e) => {
                                changeScore(e, item.id);
                              }}
                            />
                          )}
                        </CFormGroup>
                      </CCol>
                      {item.result_announce_at == null && (
                        <CCol className={'col-sm-2 text-right'}>
                          <CFormGroup>
                            <CButton
                              color='danger'
                              className='btn-sm mb-1'
                              onClick={(e) => {
                                setConfirmOptions({
                                  ...confirmOptions,
                                  content: `Are you sure you want to remove this Match from this Game Week?`,
                                  onConfirm: () => removeMatch(item),
                                });
                                setShowConfirmModal(true);
                              }}
                            >
                              <CIcon name={'cil-trash'} />
                            </CButton>{' '}
                            <br />
                            <CButton
                              color='dark'
                              className='btn-sm mt-4'
                              onClick={(e) => {
                                saveScore(item.id);
                              }}
                            >
                              Add Score
                            </CButton>
                          </CFormGroup>
                        </CCol>
                      )}
                    </CRow>
                  </CAlert>
                </CCol>
              );
            })}
          </CRow>
        );
      })}

      {showConfirmModal && (
        <ConfirmationModal
          show={showConfirmModal}
          title={confirmOptions.title}
          content={() => (
            <>
              <CAlert color={confirmOptions.type} className='w-100'>
                {confirmOptions.content}
              </CAlert>
            </>
          )}
          onClose={() => {
            setShowConfirmModal(false);
          }}
          confirmText={confirmOptions.confirmText}
          onConfirm={confirmOptions.onConfirm}
        />
      )}
    </>
  );
}

export default withRouter(GameWeeks);
