
export default class AsyncStorage {
  static setItem(key, value) {
    return Promise.resolve().then(function () {
      localStorage.setItem(key, value);
    });
  }
  static getItem(key) {
    return Promise.resolve().then(function () {
      return localStorage.getItem(key);
    });
  }
  static removeItem(key) {
    return Promise.resolve().then(function () {
      return localStorage.removeItem(key);
    });
  }
  static setCookie(name, value, days) {
    return Promise.resolve().then(function () {
      let expires = "";
      if (days) {
        let date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
      }
      document.cookie = name + "=" + (value || "")  + expires + "; path=/;"// domain="+window.Api.Domain;
    });
  }
  static getCookie(name) {
    return Promise.resolve().then(function () {
      let nameEQ = name + "=";
      let ca = document.cookie.split(';');
      for(let i=0;i < ca.length;i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length,c.length);
      }
      return null;
    });
  }
  static removeCookie(name) {
    return Promise.resolve().then(function () {
      return document.cookie = name+'=; Max-Age=-99999999;';
    });
  }
}
