const _nav = [
  {
    _tag: 'CSidebarNavItem',
    name: 'Dashboard',
    to: '/admin',
    icon: 'cil-applications-settings',
  }, {
    _tag: 'CSidebarNavItem',
    name: 'Leagues',
    to: '/admin/leagues',
    icon: 'cil-grid',
  }, {
    _tag: 'CSidebarNavItem',
    name: 'Rounds',
    to: '/admin/rounds',
    icon: 'cil-list-numbered',
  },
 {
    _tag: 'CSidebarNavItem',
    name: 'Rewards',
    to: '/admin/rewards',
    icon: 'cil-badge',
  }, {
    _tag: 'CSidebarNavItem',
    name: 'Reward Settings',
    to: '/admin/reward-settings',
    icon: 'cil-badge',
  }, {
    _tag: 'CSidebarNavItem',
    name: 'Pages',
    to: '/admin/pages',
    icon: 'cil-file',
  }, {
    _tag: 'CSidebarNavItem',
    name: 'Settings',
    to: '/admin/settings',
    icon: 'cil-settings',
  },
]

export default _nav
