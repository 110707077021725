import React, { useContext, useState, useEffect } from 'react'
import AuthManager from '../utils/AuthManager'

const AuthContext = React.createContext()

export function useAuth() {
  return useContext(AuthContext)
}

export function AuthProvider({ children }) {
  const [loginPrompt, setLoginPrompt] = useState(AuthManager.currentUser)
  const [loading, setLoading] = useState(true)
  const [currentUser, setCurrentUser] = useState(AuthManager.currentUser)
  const [sidebarShow, setSidebarShow] = useState('responsive')
  const [balance, setBalance] = useState(0)


  function login(username, password) {
    return AuthManager.login(username, password);
  }

  function logout() {
    return AuthManager.logout()
  }

  function setToggleSidebar(val) {
    setSidebarShow(val)
  }

  useEffect(() => {
    AuthManager.silentLogin()
      .then(user => {
        setCurrentUser(user)
        setBalance(user.balance)
        setLoading(false)
      }).catch(error => {
        setLoading(false)
        setCurrentUser(null)
      })
  }, [])

  const values = {
    currentUser,
    balance,
    setBalance,
    sidebarShow,
    loginPrompt,
    setLoginPrompt,
    login,
    logout,
    setToggleSidebar,
    setCurrentUser
  }

  return (
    <AuthContext.Provider value={values}>
      {!loading && children}
    </AuthContext.Provider>
  )
}
