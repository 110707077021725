import { CIcon } from '@coreui/icons-react';
import {
  CAlert,
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CDataTable,
  CInput,
  CPagination,
  CRow,
  CTooltip,
} from '@coreui/react';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { defaultConfirmOptions } from '../../constants/Options';
import Backend from '../../utils/Backend';
import Notify from '../../utils/Notify';
import Loading from '../components/Loading';
import ConfirmationModal from './modals/ConfirmationModal';
import LeagueModal from './modals/LeagueModal';
import MatchesModal from './modals/MatchesModal';

function Leagues(props) {
  const nameRef = useRef();
  const [leagues, setLeagues] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedLeague, setSelectedLeague] = useState();
  const [showAddLeague, setShowAddLeague] = useState(false);
  const [showAddMatches, setShowAddMatches] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [confirmOptions, setConfirmOptions] = useState(defaultConfirmOptions);

  // const searchLeagues = async () => {
  //   await getLeagues();
  // };

  const deleteLeague = async (league) => {
    if (league == null) {
      setShowConfirmModal(false);
      Notify.error('Please select League');
      return;
    }

    try {
      await Backend.deleteLeague(league.id);
      const updatedLeagues = leagues.filter((item) => item.id !== league.id);
      setLeagues([...updatedLeagues]);
      setShowConfirmModal(false);
      setSelectedLeague(null);
      Notify.success('League deleted successfully.');
    } catch (error) {
      setShowConfirmModal(false);
      Notify.error(error.message);
    }
  };

  const getLeagues = async () => {
    setLoading(true);

    try {
      let params = `?has_active_round=1&per_page=10&page=${currentPage}`;
      if (nameRef.current.value) {
        params += `&name=${nameRef.current.value}`;
      }

      const response = await Backend.getLeagues(params);
      if (response) {
        setLastPage(response.last_page);
        setLeagues([...response.data]);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      // Notify.error(error.message)
      setLoading(false);
    }
  };

  useEffect(() => {
    getLeagues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  return (
    <>
      <CRow>
        <CCol>
          <h4>Leagues and Tournaments</h4>
        </CCol>
        <CCol className='text-right'>
          <CButton
            color='dark'
            className='btn-sm'
            onClick={() => setShowAddLeague(!showAddLeague)}
          >
            <CIcon name='cil-plus' /> Add League
          </CButton>
        </CCol>
      </CRow>

      <CRow className={'mt-3'}>
        <CCol xs='12' lg='12'>
          <CCard>
            <CCardHeader>
              <CRow>
                <CCol>
                  <h6>Leagues</h6>
                </CCol>
              </CRow>
              <CRow>
                <CCol className={'col-sm-6'}>
                  <CInput
                    innerRef={nameRef}
                    name='name'
                    placeholder={'Search by Name...'}
                  />
                </CCol>
                {/* <CCol className={'col-sm-4'}>
                  <CSelect
                    innerRef={branchRef}
                    name="branch"
                  >
                    <option value="">Select Department</option>
                    {
                      branches.map(branch => {
                        return (<option value={branch.id}>{branch.name}</option>)
                      })
                    }
                  </CSelect>
                </CCol>
                <CCol className={'col-sm-2'}>
                  <CButton color="dark" className="btn-sm"
                    onClick={() => searchLeagues()} ><CIcon name="cil-filter" /> Filter</CButton>

                </CCol> */}
              </CRow>
            </CCardHeader>
            <CCardBody>
              {loading ? (
                <Loading />
              ) : (
                <>
                  <CDataTable
                    items={leagues}
                    striped
                    outlined={true}
                    fields={[
                      {
                        key: 'name',
                        label: 'Name',
                      },
                      {
                        key: 'start',
                        label: 'Start Date',
                      },
                      {
                        key: 'end',
                        label: 'End Date',
                      },
                      {
                        key: 'actions',
                        label: 'Actions',
                      },
                    ]}
                    scopedSlots={{
                      start: (item) => {
                        return (
                          <td>{moment(item.start).format('DD/MM/YYYY')}</td>
                        );
                      },
                      end: (item) => {
                        return <td>{moment(item.end).format('DD/MM/YYYY')}</td>;
                      },
                      actions: (item) => {
                        return (
                          <td>
                            <CButtonGroup className={'btn-sm'}>
                              <CTooltip
                                content='View League Details'
                                placement='bottom'
                              >
                                <CButton
                                  color='success'
                                  variant='outline'
                                  className={'btn-sm'}
                                  onClick={() => {
                                    props.history.push(
                                      `/admin/leagues/${item.id}`,
                                    );
                                  }}
                                >
                                  <CIcon name='cil-grid'></CIcon>
                                </CButton>
                              </CTooltip>
                              <CTooltip
                                content='Add League Matches'
                                placement='bottom'
                              >
                                <CButton
                                  color='success'
                                  variant='outline'
                                  className={'btn-sm'}
                                  onClick={() => {
                                    setSelectedLeague(item);
                                    setShowAddMatches(true);
                                  }}
                                >
                                  <CIcon name='cil-plus'></CIcon>
                                </CButton>
                              </CTooltip>
                              <CTooltip
                                content='Edit League'
                                placement='bottom'
                              >
                                <CButton
                                  color='dark'
                                  variant='outline'
                                  className={'btn-sm'}
                                  onClick={() => {
                                    setSelectedLeague(item);
                                    setShowAddLeague(true);
                                  }}
                                >
                                  <CIcon name='cil-pen-alt'></CIcon>
                                </CButton>
                              </CTooltip>
                              <CTooltip
                                content='Delete League'
                                placement='bottom'
                              >
                                <CButton
                                  color='danger'
                                  variant='outline'
                                  className={'btn-sm'}
                                  onClick={() => {
                                    setConfirmOptions({
                                      ...confirmOptions,
                                      content: `Are you sure you want to delete ${item.name}? All matches of this League will be deleted.`,
                                      onConfirm: () => deleteLeague(item),
                                    });
                                    setShowConfirmModal(true);
                                  }}
                                >
                                  <CIcon name='cil-trash'></CIcon>
                                </CButton>
                              </CTooltip>
                            </CButtonGroup>
                          </td>
                        );
                      },
                    }}
                  />
                  <CPagination
                    className={'mt-2'}
                    activePage={currentPage}
                    pages={lastPage}
                    onActivePageChange={(i) => setCurrentPage(i)}
                  ></CPagination>
                </>
              )}
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>

      {showAddLeague && (
        <LeagueModal
          show={showAddLeague}
          league={selectedLeague}
          onAdded={(league) => {
            setSelectedLeague(null);
            leagues.unshift(league);
            setLeagues([...leagues]);
          }}
          onUpdated={(league) => {
            setSelectedLeague(null);
            const updatedLeagues = leagues.map((item) => {
              if (item.id === league.id) {
                return league;
              } else {
                return item;
              }
            });
            setLeagues([...updatedLeagues]);
          }}
          onClose={() => {
            setSelectedLeague(null);
            setShowAddLeague(false);
          }}
        />
      )}

      {showAddMatches && (
        <MatchesModal
          show={showAddMatches}
          league={selectedLeague}
          onClose={() => {
            setShowAddMatches(false);
            setSelectedLeague(null);
            getLeagues();
          }}
        />
      )}

      {showConfirmModal && (
        <ConfirmationModal
          show={showConfirmModal}
          title={confirmOptions.title}
          content={() => (
            <>
              <CAlert color={confirmOptions.type} className='w-100'>
                {confirmOptions.content}
              </CAlert>
            </>
          )}
          onClose={() => {
            setShowConfirmModal(false);
          }}
          confirmText={confirmOptions.confirmText}
          onConfirm={confirmOptions.onConfirm}
        />
      )}
    </>
  );
}

export default withRouter(Leagues);
