import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useAuth } from '../contexts/AuthContext'

export default function AdminRoute({ component: Component, ...rest }) {
  const { currentUser } = useAuth()
  return (
    <Route
      {...rest}
      render={props => {
        if (currentUser) {
          if (currentUser.role === 'admin') {
            return <Component {...props} />
          } else {
            return <Redirect to="/" />

          }
        }
        return <Redirect to="/login" />
      }}
    >
    </Route>
  )
}