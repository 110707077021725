import {
  CCreateElement,
  CSidebar,
  CSidebarBrand,
  CSidebarMinimizer,
  CSidebarNav,
  CSidebarNavDivider,
  CSidebarNavDropdown,
  CSidebarNavItem,
  CSidebarNavTitle,
} from '@coreui/react';
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import AuthManager from '../../utils/AuthManager';
import Backend from '../../utils/Backend';
import navigations from './_nav';

function Sidebar(props) {
  const { sidebarShow, setToggleSidebar, setCurrentUser } = useAuth();
  // eslint-disable-next-line no-unused-vars
  const [minimized, setMinimized] = useState(true);
  // const [menu, setMenu] = useState([]);
  const [pages, setPages] = useState([]);

  const getPages = async () => {
    try {
      const response = await Backend.getAllPages();
      if (response.data) {
        setPages([...response.data]);
        // let pageMenus = response.data.map(page => {
        //   return {
        //     _tag: 'CSidebarNavItem',
        //     name: page.title,
        //     exact: true,
        //     path: `/page/${page.id}`,
        //   }
        // })
        // setMenu([...navigations, ...pageMenus])
      } else {
        // setMenu(navigations)
      }
    } catch (e) {}
  };

  useEffect(() => {
    getPages();
    return () => {};
  }, []);

  return (
    <CSidebar
      colorScheme={'light'}
      onMinimizeChange={(val) => {
        setMinimized(val);
      }}
      show={sidebarShow}
      onShowChange={(val) => {
        setToggleSidebar(val);
      }}
    >
      <CSidebarBrand className='d-md-down-none'></CSidebarBrand>
      <CSidebarNav>
        <img
          src={'../assets/logo.jpg'}
          alt='logo'
          style={{ maxWidth: '90%' }}
        />
        <CCreateElement
          items={navigations}
          components={{
            CSidebarNavDivider,
            CSidebarNavDropdown,
            CSidebarNavItem,
            CSidebarNavTitle,
          }}
        />
        <ul style={{ listStyle: 'none', padding: 0 }}>
          {pages.map((page) => {
            return (
              <li className='c-sidebar-nav-item' key={page.id}>
                <a
                  className='c-sidebar-nav-link'
                  tabIndex='0'
                  href={`/page/${page.id}`}
                >
                  {page.title}
                </a>
              </li>
            );
          })}
          {AuthManager.getCurrentUser() && (
            <li className='c-sidebar-nav-item'>
              <div
                className='c-sidebar-nav-link'
                tabIndex='0'
                onClick={async () => {
                  await AuthManager.logout();
                  setCurrentUser(null);
                  props.history.push('/');
                }}
              >
                LOGOUT
              </div>
            </li>
          )}
        </ul>
      </CSidebarNav>
      <CSidebarMinimizer className='c-d-md-down-none' />
    </CSidebar>
  );
}

export default withRouter(Sidebar);
