import { CIcon } from '@coreui/icons-react';
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CNav,
  CNavItem,
  CNavLink,
  CRow,
  CTabContent,
  CTabPane,
  CTabs,
} from '@coreui/react';
import React, { useEffect, useState } from 'react';
import { useParams, withRouter } from 'react-router-dom';
import Backend from '../../utils/Backend';
import Details from './components/rounds/Details';
import GameWeeks from './components/rounds/GameWeeks';
import Results from './components/rounds/Results';
import GameWeekModal from './modals/GameWeekModal';

function Round(props) {
  const { roundId } = useParams();
  const [round, setRound] = useState(null);
  const [showAddGameWeek, setShowAddGameWeek] = useState(false);

  const initRound = async () => {
    try {
      const response = await Backend.getRound(roundId);
      if (response.data) {
        setRound(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    initRound();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <CRow>
        <CCol>
          <h4>{round ? round.name : ''}</h4>
        </CCol>
        <CCol className='text-right'>
          <CButton
            color='dark'
            className='btn-sm'
            onClick={() => setShowAddGameWeek(!showAddGameWeek)}
          >
            <CIcon name='cil-plus' /> Add/Edit Game Weeks
          </CButton>
        </CCol>
      </CRow>

      <CRow className={'mt-3'}>
        <CCol className={'col-12'}>
          <CTabs activeTab='gameweek_tab'>
            <CCard>
              <CCardHeader className={'pb-0'}>
                <CNav variant='tabs'>
                  <CNavItem>
                    <CNavLink data-tab='gameweek_tab'>Game Weeks</CNavLink>
                  </CNavItem>
                  <CNavItem>
                    <CNavLink data-tab='round_details'>Round Details</CNavLink>
                  </CNavItem>
                  <CNavItem>
                    <CNavLink data-tab='round_results'>Round Results</CNavLink>
                  </CNavItem>
                </CNav>
              </CCardHeader>
              <CCardBody>
                <CTabContent>
                  <CTabPane data-tab='gameweek_tab'>
                    {round && <GameWeeks gameWeeks={round.game_weeks} />}
                  </CTabPane>
                  <CTabPane data-tab='round_details'>
                    {round && <Details round={round} />}{' '}
                  </CTabPane>
                  <CTabPane data-tab='round_results'>
                    {round && <Results round={round} />}
                  </CTabPane>
                </CTabContent>
              </CCardBody>
            </CCard>
          </CTabs>
        </CCol>
      </CRow>

      {showAddGameWeek && (
        <GameWeekModal
          show={showAddGameWeek}
          round={round}
          onClose={() => {
            setShowAddGameWeek(false);
            initRound();
          }}
        />
      )}

      {/* {
        showConfirmModal &&
        (
          <ConfirmationModal
            show={showConfirmModal}
            title={confirmOptions.title}
            content={() =>
            (
              <>
                <CAlert color={confirmOptions.type} className="w-100">
                  {confirmOptions.content}
                </CAlert>
              </>
            )
            }
            onClose={() => {
              setShowConfirmModal(false)
            }}
            confirmText={confirmOptions.confirmText}
            onConfirm={confirmOptions.onConfirm}
          />
        )
      } */}
    </>
  );
}

export default withRouter(Round);
